// import { apiBaseUrl} from '../helpers/common';
import * as actionTypes from '../types/types';
import {handleResponse} from '../helpers/userServices';


export const fetchStart = () => {
    return {
        type: actionTypes.FETCH_NEWS_BEGIN
    }
}

export const fetchNewsSuccess = (data) => {
    return {
        type: actionTypes.FETCH_NEWS_SUCCESS,
        payload: data.data
    }
}
export function getAllNews(id=`${process.env.REACT_APP_PLATFORM_SUFFIX}`, pagenum=1) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/all-news/${id}/${pagenum}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`}),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchNewsSuccess(data))
        });
    }
}


export const singlefetchStart = () => {
    return {
        type: actionTypes.FETCH_SINGLE_NEWS_BEGIN
    }
}

export const singlefetchNewsSuccess = (data) => {
    return {
        type: actionTypes.FETCH_SINGLE_NEWS_SUCCESS,
        payload: data.data
    }
}
export function getSingleNews(id,idd=`${process.env.REACT_APP_PLATFORM_SUFFIX}`) {
    return dispatch => {
        dispatch(singlefetchStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-single-news/${id}/${idd}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`}),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(singlefetchNewsSuccess(data))
        });
    }
}

export const fetchAllPlaylistSuccess = (data) => {
    return {
        type: actionTypes.FETCH_ALL_PLAYLIST_SUCCESS,
        payload: data.data
    }
}

export function getAllPlaylist(pagenum=1) {
    return dispatch => {
        dispatch(singlefetchStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-all-playlist/${pagenum}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchAllPlaylistSuccess(data))
        });
    }
}