  
 import * as types from "../types/types";

 const initalState = {
     loading: true,
     metas: [],
 }
 
 const metaReducer = (state = initalState, action) => {
     switch (action.type) {
         case types.FETCH_METAS_BEGIN:
             return {
                 ...state,
                 loading: true
             }
         case types.FETCH_META_SUCCESS:
             return {
                 ...state,
                 loading: false,
                 metas: action.payload
             }
            
             
         default:
             return state
     }
 }
 
 export default metaReducer;