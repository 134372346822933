import * as types from "../types/types";

const initalState = {
    loading: true,
    data: [],
    singleusersmatches:[],
    singlematchdata:[],
    allcountdata:[],
    totalcount:''
}

const matchReducer = (state = initalState, action) => {
    switch (action.type) {
       
        case types.FETCH_DATA_BEGIN:
            return {
                ...state,
                loading: true
            }
        case types.FETCH_DATA_SUCCESS:
            return {
                
                ...state,
                loading: false,
                data: action.payload
            }
            case types.FETCH_MATCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
            case types.FETCH_MATCH_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                singlematchdata: action.payload
            }
            case types.FETCH_DATA_ACTIVE_MATCHES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
            case types.FETCH_USERS_MATCH_SUCCESS:
            return {
                ...state,
                loading: false,
                singleusersmatches: action.payload
            }
            case types.FETCH_COUNTBY_MONTH_SUCCESS:
            return {
                ...state,
                loading: false,
                allcountdata: action.payload,
                totalcount: action.paylo
            }
        default:
            return state
    }
}

export default matchReducer;