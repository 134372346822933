import * as types from "../types/types";

const initalState = {
    loading: true,
    data: [],
    footerdata:[],
    platformimages:[],
    pagecontent:[],
    dynamicoptions:[],
    infotipsdata:[],
    infotipsdataadmin:[],
    craousalfooterloding:[],
    craousalfooterdata:[],
    usermenuloding:[],
    allmenus:[],
    allAdminMenus:[],
    customadminsubmenudata:[],
    pagedata: [],
    pagedataloading: [],
    customsidebarmenudata:[],
    alldynamiccssversions:[],
    allusermenus:[],
    footersociallinks:[],
    secureclientdataloading:true, 
    commonsecureclientdata:true,
    secureclientdata: [],
    commonsecureclientfirebasedata:[],
    allfeeds: [],
    singlefeedcomments:[],

    singlefeedloading:true,
    singlefeeddata:[],
    createsecuresignedurldataloading:true,
    createsecuresignedurldata:[],
    allcustomfeeds:[],

    csvdatadetailsloading:true,
    csvdatadetails:[],

    homepagealldataloading:true,
    homepagealldata:[],

    emailtempsuccess:true,
    emailtempsuccessdata:[],
    clientdetailsloading: true,
    clientdetails:[],
    logs:[],
    allhelpcentermenus: [],
    singlehelpcentermenus: '',
    allpetitions:'',
    allhighlights:[],
    customadminnewmenuloding: true,
    customadminnewmenudata: []

}

const XrDynamicReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.FETCH_DYNAMIC_BEGIN:
            return {
                ...state,
                loading: true
            }
        case types.FETCH_ALL_DYNAMIC_SUCCESS:
            return {
                ...state,   
                loading: false,
                data: action.payload
            }
        case types.FETCH_FOOTER_CRAOUSAL_BEGIN:
            return {
                ...state,
                craousalfooterloding: true,
                //: action.payload
            }
        case types.FETCH_FOOTER_CRAOUSAL_SUCCESS:
            return {
                ...state,   
                craousalfooterloding: false,
                craousalfooterdata: action.payload
            }
        case types.FETCH_MENUUSER_BEGIN:
            return {
                ...state,
                usermenuloding: true,
                //: action.payload
            }
        case types.FETCH_MENUUSER_SUCCESS:
            return {
                ...state,   
                usermenuloding: false,
                allmenus: action.payload
            }
        case types.FETCH_MENU_ADMIN_BEGIN:
            return {
                ...state,
                adminmenuloding: true,
                //: action.payload
            }
        case types.FETCH_MENU_ADMIN_SUCCESS:
            return {
                ...state,
                adminmenuloding: false,
                allAdminMenus: action.payload
            }
        case types.FETCH_ALL_DYNAMIC_FOOTER_SUCCESS:
            return {
                ...state,
                loading: false,
                footerdata: action.payload
            }
        case types.FETCH_DYNAMIC_PLATFORM_IMAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                platformimages: action.payload
            }    
        case types.FETCH_ALL_DYNAMIC_SOCIAL_LINK:
            return {
                ...state,
                loading: false,
                footersociallinks: action.payload
            }
        case types.FETCH_ALL_DYNAMIC_PAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                pagecontent: action.payload
            }
        case types.FETCH_ALL_DYNAMIC_OPTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                dynamicoptions: action.payload
            }
        case types.FETCH_ALL_DYNAMIC_INFOTIPS:
            return {
                ...state,
                loading: false,
                infotipsdata: action.payload
            }
        case types.FETCH_ALL_DYNAMIC_INFOTIPS_ADMIN:
            return {
            ...state,
            loading: false,
            infotipsdataadmin: action.payload
            }
        case types.FETCH_MENU_BEGIN:
            return {
                ...state,
                custommenuloding: true,
                //: action.payload
            }
        case types.FETCH_MENU_SUCCESS:
            return {
                ...state,   
                custommenuloding: false,
                custommenudata: action.payload
            }
        case types.FETCH_DYNAMIC_SIDEBAR_MENU_SUCCESS:
            return {
                ...state,
                custommenuloding: false,
                customsidebarmenudata: action.payload
            }
        case types.FETCH_ADMIN_MENU_BEGIN:
            return {
                ...state,
                customadminmenuloding: true,
            }
        case types.FETCH_ADMIN_MENU_SUCCESS:
            return {
                ...state,
                customadminmenuloding: false,
                customadminmenudata: action.payload
            }

            case types.FETCH_ADMIN_NEW_MENU_BEGIN:
                return {
                    ...state,
                    customadminnewmenuloding: true,
                }
            case types.FETCH_ADMIN_NEW_MENU_SUCCESS:
                return {
                    ...state,
                    customadminnewmenuloding: false,
                    customadminnewmenudata: action.payload
                }
        case types.FETCH_ALL_SUBMENU_SUCCESS:
            return{
                ...state,
                customadminmenuloding: false,
                customadminsubmenudata: action.payload
            }
        case types.FETCH_ALL_PERMISSIONS_SUCCESS:
            return{
                ...state,
                customadminmenuloding: false,
                allpermissiondata: action.payload
            }
        case types.FETCH_SINGLE_PAGE_BEGIN:
            return {
                ...state,
                pagedataloading: true
            }
        case types.FETCH_SINGLE_PAGE_SUCCESS:
            return {
                ...state,   
                pagedataloading: false,
                pagedata: action.payload
            }
        case types.FETCH_ALL_DYNAMIC_VERSION_SUCCESS:
            return{
                ...state,
                loading: false,
                alldynamiccssversions: action.payload
            } 
        case types.FETCH_USER_MENU_SUCCESS:
            return{
                ...state,
                loading: false,
                allusermenus: action.payload
            }
        case types.FETCH_SECURE_CLIENT_BEGIN:
            return {
                ...state,
                secureclientdataloading: true
            }
        case types.FETCH_SECURE_CLIENT_SUCCESS:
            return {
                ...state,   
                secureclientdataloading: false,
                secureclientdata: action.payload
            } 
        case types.FETCH_COMMON_SECURE_CLIENT_BEGIN:
            return{
                ...state,
                commonsecureclientdata:true
            }
        case types.FETCH_COMMON_SECURE_CLIENT_SUCCESS:
            return{
                ...state,   
                commonsecureclientdata: false,
                commonsecureclientfirebasedata: action.payload
            }
        case types.CREATE_SECURE_SIGNEDURL_BEGIN:
            return {
                ...state,
                createsecuresignedurldataloading: true
            }
        case types.CREATE_SECURE_SIGNEDURL_SUCCESS:
            return {
                ...state,   
                createsecuresignedurldataloading: false,
                createsecuresignedurldata: action.payload
            }
            case types.FETCH_SINGLE_FEED_BEGIN:
                return {
                    ...state,
                    singlefeedloading: true
                }
            case types.FETCH_SINGLE_FEED_SUCCESS:
                return {
                    ...state,   
                    singlefeedloading: false,
                    singlefeeddata: action.payload
                }
        case types.FETCH_FEEDS_SUCCESS:
            return {
                ...state,   
                loading: false,
                allfeeds: action.payload
            }
        case types.FETCH_SINGLE_FEED_COMMENTS_SUCCESS: 
                return {
                    ...state,   
                    loading: false,
                    singlefeedcomments: action.payload
                }
        case types.FETCH_CUSTOM_FEEDS_SUCCESS:
            return {
                ...state,   
                loading: false,
                allcustomfeeds: action.payload
            }
            case types.FETCH_HOMEPAGE_ALLDATA_BEGIN:
                return {
                    ...state,
                    homepagealldataloading: true
                }
                case types.FETCH_EMAIL_TEMP_BEGIN:
                return {
                    ...state,
                    emailtempsuccess: true
                }
                case types.FETCH_EMAIL_TEMP_SUCCESS:
                    return {
                        ...state,   
                        emailtempsuccess: false,
                        emailtempsuccessdata: action.payload
                    }  
            case types.FETCH_HOMEPAGE_ALLDATA_SUCCESS:
                return {
                    ...state,   
                    homepagealldataloading: false,
                    homepagealldata: action.payload
                }  
        case types.FETCH_CSV_DATA_BEGIN:
            return {
                ...state,
                csvdatadetailsloading: true
            }
        case types.FETCH_CSV_DATA_SUCCESS:
            return {
                ...state,   
                csvdatadetailsloading: false,
                csvdatadetails: action.payload
            }  
        case types.FETCH_CLIENT_DETAILS_SUCCESS:
            return {
                ...state,
                clientdetailsloading: false,
                clientdetails: action.payload
            } 
            case types.FETCH_CLIENT_LOGS:
                return {
                    ...state,
                    clientdetailsloading: false,
                    logs: action.payload
                }  
        case types.FETCH_CLIENT_LOGS:
            return {
                ...state,
                clientdetailsloading: false,
                logs: action.payload
            }  
        case types.FETCH_HELP_CENTER_MENU_SUCCESS:
            return {
                ...state,
                loading: false,
                allhelpcentermenus: action.payload
            }
        case types.FETCH_SINGLE_HELP_CENTER_MENU_SUCCESS:
            return {
                ...state,
                loading: false,
                singlehelpcentermenus: action.payload
            }
        case types.FETCH_ACTIVE_CENTER_DATA:
            return {
                ...state,
                loading: false,
                activecenterdata: action.payload
            }
        case types.FETCH_ALL_PETITIONS:
            return {
                ...state,
                loading: false,
                allpetitions: action.payload
            }
        case types.FETCH_ALL_HIGHLIGHTS:
            return {
                ...state,
                loading: false,
                allhighlights: action.payload
            }
        default:
            return state
    }
}

export default XrDynamicReducer;