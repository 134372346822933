import React, { Component } from 'react';
import SignupHeader from './SignupHeader';
import Verifysignup from '../../components/Verifysignup';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import XRFooter from './XRFooter';
import parseJwt from '../../store/helpers/common';

import XRSwitch from './../../components/xrgames/XRSwitch';
import {
    getSingleUserdetails
} from "../../store/actions/userActions";
import $ from 'jquery';

class XRInnerLayoutWIthoutFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            light_theme:true,
            submitlayoutrefresh: "no",
        }
    }
    fetchData() {
        if (localStorage.getItem('token')) {
            if(localStorage.getItem('token') === 'undefined'){
                this.setState({
                    session_msg :'Session is expired, Please Login Again.'
                })
                setTimeout(function () {
                    localStorage.clear('token');
                    localStorage.clear('session_expired');
                    this.setState({
                        session_msg: ""
                    })
                }.bind(this), 5000);
            }else{
                const token = localStorage.getItem("token");
                const currdetails = parseJwt(localStorage.getItem('token'));
                var today = new Date();
                var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
                if (currdetails.exp <  Date.now()) {
                }else{
                }
                const currid = currdetails.sub;
                this.setState({ currid: currid, isLoggedIn: true });
                this.props.dispatch(getSingleUserdetails(currid));
                if (currdetails.urxrs == '$In&f67l@n%eR76Hi7') {
                    this.setState({
                        ifluencer: true
                    })
                }else if(currdetails.urxrs == '$Us&er67l@vi%eRst76Heri7'){
                        this.setState({
                            visiter: true
                        })
                } else if (currdetails.urxrs == '#u$43*70&78h7'){
                    this.setState({
                        userLogin: true
                    })
                }
            }
        } else {
            this.setState({
                loggedout: true
            })
        }
        if(localStorage.getItem('sc_theme_yf')){
            this.setState({
                light_theme:localStorage.getItem('sc_theme_yf') == 'true' ? true : false
            })
        }else{
            this.setState({
                light_theme:true
            })
            localStorage.setItem("sc_theme_yf", true);
        }
       
    }
    componentDidMount() {
        this.fetchData();
    }
    submitlayoutrefreshback = () => {
        this.setState({
            submitlayoutrefresh: "no"
        })
        //"refresh back"
    }
    submitlayoutrefresh = () => {
        this.setState({
            submitlayoutrefresh: "yes"
        })
        this.fetchData();
        
    }
    render() {
        $(window).on('load', function () {
            $('input[type=text], input[type=password]').attr('autoComplete', 'off');
        });
       
        return (
            < >
                <div className={`main-content-sec ${this.state.light_theme  ? '' : 'sc-yf'}`}>
                    <SignupHeader  submitlayoutrefreshback = {this.submitlayoutrefreshback} submitlayoutrefreshstate = {this.state.submitlayoutrefresh} submitlayoutrefresh={this.submitlayoutrefresh}/>
                    <div className="profile-banner">
                        {/* <div className="custom-container"> */}
                            <div className="">
                                {/* <div className="row">
                                    <div className="col-md-12 my-4">
                                        <XRSwitch />
                                    </div>
                                </div> */}
                                {/* <div className="page_content"> */}
                                    {/* <div className="main-content w-100"> */}
                                        {/* <div className=""> */}
                                            {/* <div className="sec games-list"> */}
                                                {this.props.children}
                                            {/* </div> */}
                                        {/* </div> */}
                                    {/* </div> */}
                                {/* </div> */}
                            </div>
                        {/* </div> */}
                    </div>
                    {/* <XRFooter /> */}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    singleuserdata: state.userDetails.singleuserdata,
    singleuserdataloading: state.userDetails.loading
})
export default withRouter(connect(mapStateToProps)(XRInnerLayoutWIthoutFooter));
