  
 import * as types from "../types/types";

 const initalState = {
     loading: true,
     lobbyallbanners: [],
     lobbysingbanner: [],

     lobbydesktopbanner: [],
     lobbymobilesingbanner: [],
     lobbysinghomebanner: [],
     lobbyalldata:[],
     streamdata:[],
     allstreamdata:[],

     
 }
 
 const LobbyReducer = (state = initalState, action) => {

     switch (action.type) {
         case types.FETCH_LOBBY_BANNER_BEGIN:
             return {
                 ...state,
                 loading: true
             }

        case types.FETCH_STREAM_BEGIN:
            return{
                ...state,
                loading:true
            }
         case types.FETCH_LOBBY_BANNER_SUCCESS:
             return {
                 ...state,
                 loading: false,
                 allbanners: action.payload
             }
             case types.FETCH_SINGLE_LOBBY_BANNER_SUCCESS:
             return {
                 ...state,
                 loading: false,
                 singbanner: action.payload
             }
             case types.FETCH_LOBBY_DESK_BANNER_SUCCESS:
             return {
                 ...state,
                 loading: false,
                 lobbydesktopbanner: action.payload
             }
             case types.FETCH_LOBBY_MOB_BANNER_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    lobbymobilesingbanner: action.payload
                }
                case types.FETCH_LOBBY_HOME_BANNER_SUCCESS:
                    return {
                        ...state,
                        loading: false,
                        singhomebanner: action.payload
                    }

                case types.FETCH_LOBBY_ALL_DATA_SUCCESS:
                    return{
                        ...state,
                        loading:false,
                        lobbyalldata:action.payload
                    }

                case types.FETCH_STREAM_SUCCESS:
                    return{
                        ...state,
                        loading:false,
                        streamdata:action.payload

                    }
                case types.FETCH_ALL_STRAM_DATA_SUCCESS:
                    return{
                        ...state,
                        loading:false,
                        allstreamdata:action.payload
                    }
             
         default:
             return state
     }
 }
 
 export default LobbyReducer;