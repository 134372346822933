import React, { Component } from 'react';
import { withRouter } from 'react-router';

class AdminFooter extends Component {
    render() {
        return (
        <footer className="pt-0 pb-0 footer-admin">
        {/* <div className="container-fluid">
            <div className="sponsoredfooter">
                <div className="align-items-center d-flex justify-content-center mt-2">
                    <p>Powered by </p><a target="_blank" href="https://xrsports.gg/"><img src="https://storage.googleapis.com/al-sports/xr_all/XR_logo_black.png" /></a>
                </div>
            </div>
        </div> */}
                <div className="modal success-message add-funds fade" id="modal-share-popup" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header py-0 text-right d-block border-0">
                                {/* <h5 className="mb-0">Confirm Minting</h5> */}
                                <button type="button" className="close mt-0" data-bs-dismiss="modal">
                                        <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body text-center mx-4 pt-0">
                                <h6 className="text-center mt-0 mb-0">Congrats, your {localStorage.getItem("item_type")} is live!</h6>
                                <p>Share it with others</p>
                                <div className="float-start w-100 mt-0">
                                 <div className="socialshare">
                                 <a href="#"><img className="img-fluid img-br" src="https://storage.googleapis.com/al-sports/xrgames/social-icons/Insta.gif" alt="thumbnail"/><p>Instagram</p></a>
                                 </div>
                                    <div className="socialshare">
                                     <a href="#"><img className="img-fluid img-br" src="https://storage.googleapis.com/al-sports/xrgames/social-icons/FB.gif" alt="thumbnail"/>
                                     <p>Facebook</p>
                                     </a>
                                     </div>
                                     <div className="socialshare">
                                     <a href="#"><img className="img-fluid img-br" src="https://storage.googleapis.com/al-sports/xrgames/social-icons/Twitter.gif" alt="thumbnail"/>
                                     <p>Twitter</p>
                                     </a>
                                     </div>
                                     <div className="socialshare mt-3">
                                     <a href="#"><img className="img-fluid img-br" src="https://storage.googleapis.com/al-sports/xrgames/social-icons/linkedin.gif" alt="thumbnail"/>
                                     <p>Linkedin</p>
                                     </a>
                                     </div>
                                     <div className="socialshare mt-3">
                                     <a href="#"><img className="img-fluid img-br" src="https://storage.googleapis.com/al-sports/xrgames/social-icons/sms.gif" alt="thumbnail"/>
                                     <p>SMS</p>
                                     </a>
                                     </div>
                                     <div className="socialshare mt-3">
                                     <a href="#"><img className="img-fluid img-br" src="https://storage.googleapis.com/al-sports/xrgames/social-icons/Email.gif" alt="thumbnail"/>
                                     <p>Email</p>
                                     </a>
                                     </div>
                                 </div>    
                                 <div className="col-md-12 my-4 float-start">
                                    <div className="copy-text justify-content-between">
                                        <input type="text" className="copy-input-text" defaultValue={localStorage.getItem("item_url")}/>
                                        <button> <i className="far fa-clone"></i></button>
                                    </div>
                                 </div>            
                            </div>
                        </div>	
                    </div>
                </div>
    </footer>
    );
    }
}

export default withRouter(AdminFooter);