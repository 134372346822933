import React, { Component } from 'react';
import { withRouter } from 'react-router'

class Verifysignup extends  Component {
  constructor(props) {
    super(props);
    this.state = {
      // Your state properties here
    };
  }

  render() {
    return (
            <>
            <p>"hello"</p>
            </>
    );
  }
}

export default withRouter(Verifysignup);
