import * as types from "../types/types";

const initalState = {
    loading: true,
    chatusers: [],
}

const FirebaseReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.FETCH_DATA_BEGIN_CHAT:
            return {
                ...state,
                loading: true
            }
        case types.FETCH_CHAT_USERS_DATA:
            return {
                ...state,
                loading: false,
                chatusers: action.payload
            }

        default:
        return state
    }
}

export default FirebaseReducer;