  
 import * as types from "../types/types";

 const initalState = {
     loading: true,
     allrelationdata:[],
     singlerewarddata:[]
 }
 
 const RelationReducer = (state = initalState, action) => {

     switch (action.type) {
         case types.FETCH_RELATION_DATA_BEGIN:
             return {
                 ...state,
                 loading: true
             }
        
             case types.FETCH_RELATION_SUCCESS:
             return {
                 ...state,
                 loading: false,
                 allrelationdata: action.payload
             }
           
             
         default:
             return state
     }
 }
 
 export default RelationReducer;