import * as types from "../types/types";

const initalState = {
    loading: true,
    allchannels: [],
    activechannels:[],
}

const StreamReducer = (state = initalState, action) => {

    switch (action.type) {
        case types.FETCH_DATA_BEGIN:
            return {
                ...state,
                loading: true
            }
        case types.FETCH_ACTIVE_CHANNEL_BEGIN:
            return {
                ...state,
                loading: false,
                activechannels: action.payload
            }
        case types.FETCH_ALL_CHANNEL:
            return {
                ...state,
                loading: false,
                allchannels: action.payload
            }
        
        default:
            return state
    }
}

export default StreamReducer;