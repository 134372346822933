import { type } from "jquery";
import * as types from "../types/types";

const initalState = {
    communities:[],
    allcommunities:[],
    allcommunitiesevents:[],
    allcommunitiesuserteams:[],
    communitiesdrafttour:[],
    allcommunitiesteams:[],
    allcommunitiestourtickets:[],
    singlecommunity:[],
    communitybanners:[],
    allcommunityinactivetour:[],
    allcommunity:[],
    allcommevents:[],
    allcommteams:[],
    allcommgalleryimg:[],
    communitiesloading:true,
    allcommunitiestours:[],
    allcommrequestedmembers:[],
    communitiessixseries:[],
    sixseries:[],
    superadmincommunities:[],
    allcommunitiesdata: [],
}

const communityReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.FETCH_COMMUNITY_START:
            return {
                ...state,
                communitiesloading: true
            }
        case types.FETCH_ALL_COMMUNITY_SUCCESS:
            return {
                ...state,
                communitiesloading: false,
                communities: action.payload
            } 
        case types.FETCH_COMMUNITY_TOURS_SUCCESS:
            return {
                ...state,
                communitiesloading: false,
                allcommunities: action.payload
            } 
        case types.FETCH_COMMUNITY_TOURS_SUCCESS_FOR_COMMUNITY:
            return {
                ...state,
                communitiesloading: false,
                allcommunitiestours: action.payload
            }
        case types.FETCH_COMMUNITY_EVENT_SUCCESS:
            return {
                ...state,
                communitiesloading: false,
                allcommunitiesevents: action.payload
            } 
        case types.FETCH_COMMUNITY_USERS_TEAM_SUCCESS:
            return {
                ...state,
                communitiesloading: false,
                allcommunitiesuserteams: action.payload
            } 
        case types.FETCH_ALL_COMMUNITY_LEADER_SUCCESS:
            return{
                ...state,
                communitiesloading: false,
                communitiesleader: action.payload,
                communitiesleadercount: action.payloadd
            }
        case types.FETCH_COMMUNITY_MEMBER_SUCCESS:
            return{
                ...state,
                communitiesloading: false,
                communitiesmember: action.payload
            }
        case types.FETCH_BANNED_MEMBER_SUCCESS:
            return{
                ...state,
                communitiesloading: false,
                communitiesbanmember: action.payload
            }   
        case types.FETCH_DELETED_MEMBERS_SUCCESS:
            return{
                ...state,
                communitiesloading: false,
                communitiesdeletedmember: action.payload
            }    
        case types.FETCH_COMMUNITY_DRAFT_TOURNAMENT:
            return{
                ...state,
                communitiesloading: false,
                communitiesdrafttour: action.payload,
            }   
             
        case types.FETCH_ALL_COMMUNITY_TEAM:
            return{
                ...state,
                communitiesloading: false,
                allcommunitiesteams: action.payload,
            } 
        case types.FETCH_COMMUNITY_TOURTICKSETS:
            return{
                ...state,
                communitiesloading: false,
                allcommunitiestourtickets: action.payload,
            }    
        case types.FETCH_COMMUNITY_INACTIVE_TOURS:
            return{
                ...state,
                communitiesloading: false,
                allcommunityinactivetour: action.payload,
            }    
        case types.FETCH_SINGLE_COMMUNITY:
            return{
                ...state,
                communitiesloading: false,
                singlecommunity: action.payload,
                communitybanners: action.payloadd,
            } 
            
        case types.FETCH_SINGLE_COMMUNITY_FOR_ALL:
            return {
                ...state,
                communitiesloading: false,
                singlecommunity: action.payload,
                communitybanners: action.payloadd,
            }
        case types.FETCH_ALL_COMMUNITY_EVENT_SUCCESS:
            return{
                ...state,
                communitiesloading: false,
                allcommunity: action.payload,
            }   
        case types.FETCH_ALL_COMM_EVENT_SUCCESS: 
            return{
                ...state,
                communitiesloading: false,
                allcommevents: action.payload,
            }
        case types.FETCH_ALL_COMM_TEAMS_SUCCESS: 
            return{
                ...state,
                communitiesloading: false,
                allcommteams: action.payload,
            }
        case types.FETCH_ALL_COMM_GALLERY_SUCCESS: 
            return{
                ...state,
                communitiesloading: false,
                allcommgalleryimg: action.payload,
            } 
        case types.FETCH_COMM_SETTINGS_SUCCESS: 
            return{
                ...state,
                communitiesloading: false,
                allcommsetting: action.payload,
            } 
        case types.FETCH_COMM_REQUESTED_MEMBERS_SUCCESS:
            return{
                ...state,
                communitiesloading: false,
                allcommrequestedmembers: action.payload,
            }          
        case types.FETCH_COMMUNITY_SIX_SERIES:
            return {
                ...state,
                communitiesloading: false,
                communitiessixseries: action.payload
            }   
        case types.FETCH_SIX_SERIES:
            return {
                ...state,
                communitiesloading: false,
                sixseries: action.payload
            }  
        case types.FETCH_SUPERADMIN_COMMUNITY_SUCCESS:
            return {
                ...state,
                communitiesloading: false,
                superadmincommunities: action.payload
            }
            case types.FETCH_COMMUNITY_SUCCESS:
            return {
                ...state,
                communitiesloading: false,
                allcommunitiesdata: action.payload
            }
        default:
            return state
    }
}

export default communityReducer;

