import React from "react";
import { withRouter } from "react-router-dom";
const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  } catch (e) {
    return null;
  }
};
const AuthVerify = (props) => {
  props.history.listen(() => {
    const user =localStorage.getItem('token');
    if (user) {
      const decodedJwt = parseJwt(user); 
    //    if (1000 < Date.now()) {
      if (decodedJwt.exp * 1000 < Date.now()) {
        localStorage.clear('token');
        localStorage.setItem('session_expired', true);
        //  this.setState({ isLoggedIn: false, });
        props.history.push('/login');
        window.location.reload();
      }else{
        // console.log("auth data..........")
        props.submitlayoutrefresh();
      }
    }
  });
  return <div>
  </div>;
};
export default withRouter(AuthVerify);