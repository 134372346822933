  
 import * as types from "../types/types";

 const initalState = {
     loading: true,
     tab:'',
     allsponsors:[],
     singlesponsor:[]
 }
 
 const SponsorReducer = (state = initalState, action) => {

     switch (action.type) {
         case types.FETCH_SPONSOR_DATA_BEGIN:
             return {
                 ...state,
                 loading: true
             }
        
             case types.FETCH_SPONSOR_SUCCESS:
             return {
                 ...state,
                 loading: false,
                 allsponsors: action.payload
             }
             case types.FETCH_SINGLE_SPONSOR_SUCCESS:
             return {
                 ...state,
                 loading: false,
                 singlesponsor: action.payload
             }
             
         default:
             return state
     }
 }
 
 export default SponsorReducer;