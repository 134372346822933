import * as types from "../types/types";

const initalState = {
    loading: true,
    teamnotiloading: true,
    envynotiloading: true,
    teamnotidata: [],
    envynotidata:[]
}

const notificationsReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.FETCH_NOTIFICATIONS_BEGIN:
            return {
                ...state,
                loading: true,
                teamnotiloading: true,
                envynotiloading: true,
            }
        case types.FETCH_TEAM_NOTI_SUCCESS:
            return {
                ...state,
                teamnotiloading: false,
                teamnotidata: action.payload
            }
            case types.FETCH_ENVY_NOTI_SUCCESS:
            return {
                ...state,
                envynotiloading: false,
                envynotidata: action.payload
            }
           
        default:
            return state
    }
}

export default notificationsReducer;