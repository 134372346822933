  
 import * as types from "../types/types";

 const initalState = {
    allcards:[],
    allcardsloading: true,
    cardloading:[],
    cardloading: true,
 }
 
 const stripeReducer = (state = initalState, action) => {

     switch (action.type) {
        case types.FETCH_CARDS_BEGIN:
            return {
                ...state,
                allcardsloading: true
            }
        case types.FETCH_CARDS_SUCCESS: 
            return {
                ...state,
                allcardsloading: false,
                allcards: action.payload
            }
        case types.FETCH_SINGLE_CARD_BEGIN:
            return {
                ...state,
                cardloading: true
            }
        case types.FETCH_SINGLE_CARD_SUCCESS:
            return {
                ...state,
                cardloading: false,
                card: action.payload
            }
        default:
            return state
     }
 }
 
 export default stripeReducer;