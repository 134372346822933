import React, { Component } from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { NavLink, withRouter } from 'react-router-dom';
import parseJwt, { apiBaseUrl, logo_dark, swalpopup, deleteRecord, banner_presenting } from '../store/helpers/common';
import getAllAdminDataMenus from './../store/actions/MediaAction'
import axios from 'axios';
import $ from 'jquery';
import { getDynamicplatformimages } from "../store/actions/XrDynamicAction";
import renderHTML from 'react-render-html';
import moment from "moment-timezone";
import { getUserdetails } from "../store/actions/userActions";
import { getsecureclientData } from '../store/actions/XrDynamicAction';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getAdminLogs } from "../store/actions/XrDynamicAction";
import { FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import swal from 'sweetalert';
import { getSinglebanner, getAllbanners, getDeskbanners, getMobbanner } from "../store/actions/BannersAction";
import Fanhubmenus from './Fanhubmenus';

class AdminSideNavBar extends Component {
    constructor(props) {
        super(props);
        this.tourValidatorhomebanner = new SimpleReactValidator();
        this.Validatorhomepartner = new SimpleReactValidator();
        this.state = {
            isLoggedIn: false,
            propic: '',
            allpages: '',
            platform: ``,
            allVideos: '',
            adminmenuloding: '',
            allmenusadmindata: '',
            allmenusadmindatabyid: '',
            currid: '',
            userrole: '',
            allUserVideos: '',
            checkparam: '',
            bannerext: '',
            bannerimagee: '',
            bannerimage: '',
            bannertext: "",
            refresh: "no",
            description: "",
            // bannerimagefile:'',
            // banner_image_full:'',
            userdata: "",
            profile_pic: '',
            nickname: "",
        }
    }

    componentDidMount(props) {
        const { platform } = this.state;
        const currdetails = parseJwt(localStorage.getItem('token'));
        if (localStorage.getItem('token')) {

            const currid = currdetails.sub;
            const userrole = currdetails.urxrs;

            this.setState({
                currid: currid,
                userrole: userrole
            })
            if (userrole === '$Sa#79+57hD%4My5') {

                let menusDatabyid;
                menusDatabyid = {
                    method: 'GET',
                    url: `${process.env.REACT_APP_APIURL}/user/get-admin-menu-byid/${currid}`,
                    headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
                };
                axios(menusDatabyid).then((response) => {
                    this.setState({
                        allmenusadmindata: response.data.data,
                    });

                })
                    .catch((error) => {
                    });


            } else {
                // let menusData;
                // menusData = {
                //     method: 'GET',
                //     url: `${process.env.REACT_APP_APIURL}/user/get-admin-menu`,
                //     headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
                // };
                // axios(menusData).then((response) => {
                //     this.setState({
                //         allmenusadmindata: response.data.data,
                //     });

                // })
                //     .catch((error) => {
                //     });

                let menusData;
                menusData = {
                    method: 'GET',
                    url: `${process.env.REACT_APP_APIURL}/user/getAllAdminNewMenus/${currid}/userid`,
                    headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
                };
                axios(menusData).then((response) => {
                    this.setState({
                        allmenusadmindata: response.data.data,
                    });

                })
                    .catch((error) => {
                    });
            }


            this.props.dispatch(getsecureclientData());
            this.props.dispatch(getUserdetails(currid));
            this.props.dispatch(getMobbanner('home'));
            this.props.dispatch(getDeskbanners('home'));
        }
    }
    componentWillReceiveProps(nextProps) {

        if (nextProps.secureclientdata) {
            if (nextProps.secureclientdata.length > 0) {

                let client_details = parseJwt(nextProps.secureclientdata);

                this.setState({
                    clientdetails: client_details.client,
                    clientdetailsid: client_details.client.id,
                    logo_onee: client_details.client.logo_one,
                    logo_one_show: client_details.client.logo_one,
                    logo_twoo: client_details.client.logo_two,
                    logo_two_show: client_details.client.logo_two,
                    base_url: client_details.client.base_url,
                    loader_urll: client_details.client.loader_url,
                    loader_url_show: client_details.client.loader_url,
                    facebook: client_details.client.facebook,
                    twitter: client_details.client.twitter,
                    discord: client_details.client.discord,
                    banner_loaderr: client_details.client.banner_loader,
                    banner_loader_show: client_details.client.banner_loader,
                    instagram: client_details.client.instagram,
                    twitch: client_details.client.twitch,
                    aboutus: client_details.client.aboutus,
                    privacy_policy: client_details.client.privacy_policy,
                    faq: client_details.client.faq,
                    client_email: client_details.client.client_email,
                    client_phone: client_details.client.client_phone,
                    client_website: client_details.client.client_website,
                    client_address: client_details.client.client_address,
                    google_client_id: client_details.client.google_client_id,
                    footer_frame: client_details.client.footer_frame,
                    platform_theme: client_details.client.platform_theme,
                });
            }
        }
        this.setState({
            desktopbanner: nextProps.desktopbanner,
            mobilesingbanner: nextProps.mobilesingbanner,
        });

        if (nextProps.singleuserdata) {

            this.setState({
                userdata: nextProps.singleuserdata,
                nickname: nextProps.singleuserdata.nickname,
                profile_pic: nextProps.singleuserdata.profile_pic,
            })

            if (nextProps.singleuserdata.domain_details) {
                this.setState({
                    domain_name: nextProps.singleuserdata.domain_details.domain_name,
                    internal_domain_name: nextProps.singleuserdata.domain_details.internal_domain_name,
                    ip_address: nextProps.singleuserdata.domain_details.ip_address,
                    platform_domain: nextProps.singleuserdata.domain_details.platform_domain,
                    api_platform_shortname: nextProps.singleuserdata.domain_details.api_platform_shortname,
                    bucket_name: nextProps.singleuserdata.domain_details.bucket_name,
                    default_meta_description: nextProps.singleuserdata.domain_details.default_meta_description,
                    default_meta_title: nextProps.singleuserdata.domain_details.default_meta_title,
                    platform_name: nextProps.singleuserdata.domain_details.short_suffix,

                    //   chat_check:nextProps.singleuserdata.domain_details.communication_status,
                })
                if (nextProps.singleuserdata.domain_details.communication_status === "1") {
                    this.setState({ chat_check: false })
                } else {
                    this.setState({ chat_check: true })
                }
            }
        }

    }

    logout = (e) => {
        e.preventDefault();
        this.props.dispatch(getAdminLogs(this.state.currid));
        localStorage.clear('token');
        this.setState({ isLoggedIn: !this.state.isLoggedIn, });
        localStorage.setItem('intro-popup', 'yes');
        this.props.history.push('/login');
        // window.location.reload('/');
        window.location.reload();
    };
    remtrid = (e) => {
        localStorage.removeItem('trmid', 'menuName');

        $("#sidebar-wrapper").toggleClass("toggled");
    }
    ToggleSideNavv = (e) => {
        e.preventDefault();
        $("#wrapper").toggleClass("toggled");
    }

    // validateTourForm = (e) => {


    //     this.setState({ [e.target.name]: e.target.value });
    //     var res = e.target.value.match(/fake/g);
    //     if(res){
    //        let file = $(`#${e.target.id}`)[0].files[0];
    //     this.setState({imagelelogo:URL.createObjectURL(file)})
    //     }else{
    //       var fullurl = `${process.env.REACT_APP_GCPURL}/mav-header/${e.target.value}`;
    //       this.setState({imagelelogo:fullurl})
    //     }
    // }
    checkParam = (e, param) => {
        this.setState({ checkparam: param })
    }
    dataChange = (e) => {

        this.setState({ [e.target.name]: e.target.value });
        e.preventDefault();

        if (e.target.name == "bannerimagee") {
            this.setState({ [e.target.name]: e.target.value });
            var res = e.target.value.match(/fake/g);
            if (res) {
                let file = $(`#${e.target.id}`)[0].files[0];
                this.setState({ bannerimagefile: URL.createObjectURL(file) })
                $(`#${this.state.banner_id}_banner`).attr('src', URL.createObjectURL(file));
                // $(`#735775`).attr('src', URL.createObjectURL(file));
            }
        }
        if (e.target.name == "partnerimagee") {
            this.setState({ [e.target.name]: e.target.value });
            var res = e.target.value.match(/fake/g);
            if (res) {
                let file = $(`#${e.target.id}`)[0].files[0];
                this.setState({ partnerimageefile: URL.createObjectURL(file) })
                $(`#${this.state.linkid}_partner`).attr('src', URL.createObjectURL(file));
            } else {

            }
        }
    }
    // uploadVideo = () => {
    //     const{checkparam}= this.state;
    //     this.setState({video_loader:true})     
    //     var file = $(`#${checkparam}`)[0].files[0];
    //     let formData = new FormData();
    //     formData.append('key', checkparam);
    //     formData.append('file', file);
    //     const request = new Request(`${process.env.REACT_APP_APIURL}/user/addImage`,{
    //         method: "POST",
    //         headers: { 'X-Auth-Token': `${localStorage.getItem('token')}` },
    //         body: formData
    //     });
    //     return fetch(request).then(res => res.json()).then((data) => {
    //         this.setState({isSubmitVideo:false,key:'',url:'',type:''});
    //         if(data.ResponseCode == "1"){
    //             this.props.dispatch(getsecureclientData());
    //             // swal("Done", data.ResponseText, "success");
    //                     swalpopup(data.ResponseText, 'success')
    //         }else{
    //             // swal("Oops!", data.ResponseText, "error");
    //                     swalpopup(data.ResponseText, 'error')
    //         }
    //     }).catch((err) => {
    //         this.setState({isSubmitVideo:false});
    //     })
    // }


    // update_metas = () => {
    //     let formData = new FormData();
    //     // $field_name = $request->input('field_name');
    //     // $filed_value = $request->input('filed_value');
    //     // $where_filed = $request->input('where_filed');
    //     // $where_value = $request->input('where_value');
    //     formData.append('default_meta_title', this.state.default_meta_title);
    //     formData.append('default_meta_description', this.state.default_meta_description);
    //     formData.append('user_id', this.state.currid);
    //     const request = new Request(`${process.env.REACT_APP_APIURL}/user/update_hub_details`,{
    //         method: "POST",
    //         headers: { 'X-Auth-Token': `${localStorage.getItem('token')}` },
    //         body: formData
    //     });
    //     return fetch(request).then(res => res.json()).then((data) => {
    //         this.setState({isSubmitmetas:false});
    //         if(data.ResponseCode == "1"){
    //             this.props.dispatch(getUserdetails(this.state.currid));
    //             // swal("Done", data.ResponseText, "success");
    //                     swalpopup(data.ResponseText, 'success')
    //         }else{
    //             // swal("Oops!", data.ResponseText, "error");
    //                     swalpopup(data.ResponseText, 'error')
    //         }
    //     }).catch((err) => {
    //         this.setState({isSubmitmetas:false});
    //     })
    // }
    // setViewImage = (e,imgurl,id)=>{
    //     var res = imgurl.match(/fake/g);
    //     if(res){
    //        let file = $(`#${id}`)[0].files[0];
    //     this.setState({viewprizeimage:URL.createObjectURL(file)})
    //     }else{
    //       var fullurl = `${process.env.REACT_APP_GCPURL}/mav-header/${imgurl}`;
    //       this.setState({viewprizeimage:fullurl})
    //     }
    // }

    // deleteBanner = (e,id) =>{
    //     swal({
    //         title: "Are you sure to delete this Banner?",
    //         buttons: ["Cancel", "Yes"],
    //         dangerMode: true
    //     }).then(willDelete => {
    //         if (willDelete) {
    //             deleteRecord('delete-banner',id)
    //         } else {
    //         }
    //     });
    // }
    // AddhomerBanner =(e)=>{
    //     const {bannerurl,bannertype,platform_name,bannertext,screentype} = this.state;
    //     e.preventDefault();
    //     var validate = this.tourValidatorhomebanner;
    //     this.setState({ isSubmithomebanner: true });
    //     var url = this.state.url;
    //     if (validate.allValid()) {
    //         let request;
    //         let formData = new FormData();
    //         formData.append('bannerimagee', $('#bannerimagee')[0].files[0]);
    //         formData.append('url', bannerurl);
    //         formData.append('bannertype', bannertype);
    //         formData.append('screentype', screentype);
    //         formData.append('bannertext', bannertext);
    //         formData.append('platform', platform_name);
    //         formData.append('bannerlocation', 'home');
    //         request = {
    //             method: 'POST',
    //             url: `${process.env.REACT_APP_APIURL}/user/add-craousal-banner`,
    //             headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
    //             data: formData
    //         };

    //         axios(request).then((data) => {
    //             if (data.data.ResponseCode === "1") {
    //                 // swal("Done", data.data.ResponseText, "success");
    //                 swalpopup(data.data.ResponseText, "success");
    //                 this.props.dispatch(getMobbanner('home'));
    //                 this.props.dispatch(getDeskbanners('home'));
    //                 // window.location.reload();
    //                     this.setState({ isSubmithomebanner: false,bannerimagee:'',bannerurl:'',bannertype:'',bannertext:'',sponsorbanner: '', sponsormobilebanner:'' });
    //             } else {
    //                 this.setState({ isSubmithomebanner: false });
    //             }
    //         });
    //     } else {
    //             this.setState({ isSubmithomebanner: false });
    //         validate.showMessages();
    //         this.forceUpdate();
    //     }
    // }

    edithomebanner = (e, id, link, image, bannertype, screentype, bannertext, modalstatus) => {
       
        this.setState({
            'banner_id': id,
            'banner_link': link,
            'banner_image': image,
            'bannertype': bannertype,
            'screentype': screentype,
            'bannertext': bannertext,
            'refresh': "no",
        })

        var fullurll = `${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/homecarouselbanners/${image}`;
        this.setState({
            banner_image_full: fullurll,
            bannerext: fullurll.split('.').pop()
        })
        if (modalstatus == "yes") {
            window.$('#modal-container-updatebanner').modal('show');
        } else {
            window.$('#modal-container-updatebanner').modal('hide');
        }

    }
    updateBanner = (e) => {
        const { banner_link, bannertype, bannertext, screentype, banner_id } = this.state;
        e.preventDefault();
        var validate = this.tourValidatorhomebanner;
        this.setState({ isSubmithomebanner: true });
        var url = this.state.url;
        // if (validate.allValid()) {
        let request;
        let formData = new FormData();
        if (this.state.bannerimagee) {
            if ($('#bannerimagee')[0].files[0] != '--') {
                formData.append('bannerimagee', $('#bannerimagee')[0].files[0]);
            }
        } else {
            formData.append('bannerimagee', "");
            // swalpopup("Please select image as a banner")

        }

        formData.append('url', banner_link);
        formData.append('bannertype', bannertype);
        formData.append('screentype', screentype);
        formData.append('bannertext', bannertext);
        formData.append('bannerlocation', 'home');
        request = {
            method: 'POST',
            url: `${process.env.REACT_APP_APIURL}/user/update-home-page-banner/${banner_id}`,
            headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
            data: formData
        };

        axios(request).then((data) => {
            if (data.data.ResponseCode === "1") {
                // swal("Done", data.data.ResponseText, "success");
                swalpopup(data.data.ResponseText, "success");
                this.props.dispatch(getAllbanners());
                window.location.reload();
                this.setState({ isSubmithomebanner: false, bannerimagee: '', banner_link: '', bannertype: '', bannertext: '', sponsorbanner: '', sponsormobilebanner: '' });
            } else {
                this.setState({ isSubmithomebanner: false });
            }
        });
        // if (screentype === 'image') {
        //     swalpopup("Please select image as a banner")
        // } else if (screentype === 'video') {
        //     swalpopup("Please select video as a banner")
        // }
        // } else {
        //     this.setState({ isSubmithomebanner: false });
        //     validate.showMessages();
        //     this.forceUpdate();
        // }

    }

    edithomepartners = (e, link_id, link_url, partner_image, partner_title, partner_is_active, partner_description, partner_modalstatus) => {
        
        this.setState({
            'linkid': link_id,
            'linkurl': link_url,
            'image_name': partner_image,
            'title': partner_title,
            'is_active': partner_is_active,
            'description': partner_description,
            'refresh': "no",
        })

        var fullurll = "";
        if (partner_image.includes('http')) {
            fullurll = partner_image;
        } else {
            fullurll = `${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/communitypartners/${partner_image}`;
        }
        
        this.setState({
            partner_image_full: fullurll,
            partnerext: partner_image.split('.').pop()
        })
        if (partner_modalstatus == "yes") {
            window.$('#modal-container-updatepartner').modal('show');
        } else {
            window.$('#modal-container-updatepartner').modal('hide');
        }

    }

    updatepartner = (e) => {
        // const { linkurl, image_name, linkid, title, is_active, description } = this.state;
        // this.setState({ isSubmitcred: true });
        // const request = new Request(`${process.env.REACT_APP_APIURL}/user/update-platformimages`, {
        //     method: "POST",
        //     body: JSON.stringify({ linkurl, image_name, linkid, title, is_active, description  }),
        //     headers: new Headers({
        //         "Content-Type": "application/json",
        //         "X-Auth-Token": `${localStorage.getItem("token")}`
        //     })
        // });
        //  return fetch(request)
        //         .then(res => res.json())
        //         .then(data => {
        //             if (data.ResponseCode === "1") {
        //                 this.setState({ isSubmitcred: false, refresh: "yes" });
        //                 // this.props.dispatch(getDynamicplatformimages());
        //                 // swal("Done", data.ResponseText, "success");
        //                 swalpopup(data.ResponseText, "success");
        //                 document.getElementById("close_updatepartner").click();
        //             } else {
        //                 // swal("Oops!", data.ResponseText, "error");
        //                 swalpopup(data.ResponseText, "error");
        //             }
        //         })


        const { linkurl, image_name, linkid, title, is_active, description } = this.state;
        this.setState({ isSubmitcred: true });
        e.preventDefault();
        var validate = this.Validatorhomepartner;

        // var url = this.state.url;
        // const heading = "HOMESIDEBAR";
        // const image_id = ""
        if (validate.allValid()) {
            this.setState({ isSubmitcred: true });
            let request;
            let formData = new FormData();
            // linkurl, image_name, linkid, title, is_active, description 
            formData.append('partnerimagee', $('#partnerimagee')[0].files[0]);
            formData.append('image_name', image_name);
            formData.append('title', title);
            formData.append('linkurl', linkurl);
            formData.append('is_active', is_active);
            formData.append('linkid', linkid);
            formData.append('description', description);
            request = {
                method: 'POST',
                url: `${process.env.REACT_APP_APIURL}/user/update-platformimages`,
                headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
                data: formData
            };

            axios(request).then((data) => {
                if (data.data.ResponseCode === "1") {
                    this.setState({ refresh: "yes" });
                    this.setState({ isSubmitcred: false, newimage_name: '', newtitle: '', newlinkurl: '', heading: '', image_id: '', newdescription: '', partnerimagefile: "" });
                    document.getElementById("close_updatepartner").click();
                    swalpopup(data.data.ResponseText, "success");
                    this.props.dispatch(getDynamicplatformimages());
                    // window.location.reload();

                } else {
                    this.setState({ isSubmitcred: false });
                }
            });
        } else {
            this.setState({ isSubmitcred: false });
            validate.showMessages();
            this.forceUpdate();
        }
    }
    addDefaultSrcprofile = (ev) => {
        ev.target.src = `${process.env.REACT_APP_GCPURL}/Profile-pic-Activelink.svg`;
    }
    render() {
        const { allmenusadmindata } = this.state;
        // console.log(allmenusadmindata)
        let banneri = 1;
        $('#menu-toggle').on('click', function () {
            if ($("#wrapper").hasClass("toggled")) {
                $(".admin-side-menu").removeClass('hover_unbind');
                $('.collapse').removeClass('show');
            } else {
                $(".admin-side-menu").addClass('hover_unbind');
            }
        });

        $('.card-header, .card-title, .btn.btn-header-link').on('click', function () {
            $("#wrapper").addClass("toggled");
            $(".admin-side-menu").addClass('hover_unbind');
        });

        if (!$(".admin-side-menu").hasClass("hover_unbind")) {
            $(".admin-side-menu").on("mouseleave", function () {
                if (!$(this).hasClass('hover_unbind')) {
                    $(this).removeClass('hover_bind');
                    $("#wrapper,.admin-header").removeClass("toggled");
                    $("#wrapper,.admin-header").removeClass("toggled_hover");
                }
            });
        }
        // console.log(window.$REACT_APP_PLAN_NAME)
        // console.log('------------==============------------------')
        return (
            <>
                <div className={window.location.pathname === "/customize-homepage" || window.location.pathname === "/add-custom-menu" || window.location.pathname === "/navigation-boxes"  || window.location.pathname === "/lobby-banner-setup"? 'adminleftside customize-menu' : 'adminleftside'} role="navigation">
                    <div className="sidebar-collapse" id="">
                        {
                            window.location.pathname === "/customize-homepage" || window.location.pathname === "/customize-fanhublogo" || window.location.pathname === "/add-custom-menu" || window.location.pathname === "/navigation-boxes"  || window.location.pathname === "/lobby-banner-setup"?
                                <>
                                    <Fanhubmenus props={this.props} history={this.props.history} edithomebanner={this.edithomebanner} edithomepartners={this.edithomepartners} refresh={this.state.refresh} />
                                </>
                                // :
                                // window.location.pathname === "/add-custom-menu" || window.location.pathname === "/navigation-boxes" ? 
                                // <>
                                //     <Fanhubmenus props ={this.props} history={this.props.history} edithomebanner = {this.edithomebanner} edithomepartners = {this.edithomepartners} refresh = {this.state.refresh}/>
                                // </>
                                :
                                <>
                                {
                                    window.$REACT_APP_PLAN_NAME === 'base'?
                                        ''
                                    :
                                    <div className="activity-center pb-0">
                                    <NavLink to="/activity-center"><h5><i className="fa fa-home  me-2" aria-hidden="true">
                                    </i>Activity Center</h5></NavLink>
                                </div>
                                }
                                   
                                    <div className="activity-center">
                                        <NavLink to="/merchant-dashboard"><h5><i className="fas fa-chart-pie  me-2"></i>Merchant Center</h5></NavLink>
                                    </div>
                                    <div id="accordion" className="accordion initial_menubar mt-2">
                                        <div className="left_navigationbar card mb-0">
                                            {
                                                allmenusadmindata ?
                                                    Object.keys(allmenusadmindata).map((post) => {
                                                        if(allmenusadmindata[post].is_active == "0"){
                                                        let menu_title = allmenusadmindata[post].menu_title;
                                                        let menu_id = allmenusadmindata[post].menu_id;
                                                        let menu_link = allmenusadmindata[post].menu_link;
                                                        let menu_icon = allmenusadmindata[post].menu_icon;
                                                        let menu_parent = allmenusadmindata[post].menu_parent;
                                                        let sub_menus = allmenusadmindata[post].sub_menus;
                                                        let linktype = allmenusadmindata[post].linktype;
                                                        return (

                                                            menu_title == "Communityyyyyy" ?
                                                                this.state.userrole == "$Supa#79+57hDuh%+ad+iN%4My5" ?
                                                                    <>
                                                                        <div className="card-header my-0 collapsed" data-bs-toggle="collapse" key={menu_id} data-bs-parent="#accordion" href={`#menu_${menu_id}`} >
                                                                            <p className="card-title mb-0 ms-2">
                                                                                {menu_icon ?
                                                                                    <NavLink className="btn btn-header-link p-0" onClick={(e) => this.remtrid(e)} to={`#`}>{renderHTML(menu_icon)}</NavLink>
                                                                                    : ''}

                                                                                <span>{menu_title}</span>
                                                                            </p>
                                                                        </div>
                                                                        <div id={`menu_${menu_id}`} className="card-body collapse" data-bs-parent="#accordion">
                                                                            <ul className="sidemenu">
                                                                                {
                                                                                    sub_menus ? sub_menus.map((item) => {
                                                                                        if(item.is_active == "0"){
                                                                                            return (
                                                                                                <li key={item.menu_title}>
                                                                                                    {
                                                                                                        item.menu_link.includes('http') ?
                                                                                                            <a onClick={(e) => this.remtrid(e)} target="_blank" href={item.menu_link}><span>{item.menu_title}</span></a>
                                                                                                            :
                                                                                                            <NavLink onClick={(e) => this.remtrid(e)} target={linktype === 'true' ? "_blank" : ''} to={item.menu_link}><span>{item.menu_title}</span></NavLink>
                                                                                                    }
    
                                                                                                </li>
                                                                                            )
                                                                                        }
                                                                                    })
                                                                                        : ''
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                    </>
                                                                    : ""
                                                                :
                                                                this.state.userrole == "$Sa#79+57hD%4My5" ?
                                                                    <>

                                                                        <div className="card-header my-0 collapsed" data-bs-toggle="collapse" data-bs-parent="#accordion" href={`#menu_${menu_id}`} >
                                                                            <p className="card-title mb-0 ms-2">
                                                                                {menu_icon ?
                                                                                    <NavLink className="btn btn-header-link p-0" onClick={(e) => this.remtrid(e)} to={`#`}>{renderHTML(menu_icon)}</NavLink>
                                                                                    : ''}

                                                                                <span>{menu_title}</span>
                                                                            </p>
                                                                        </div>
                                                                        <div id={`menu_${menu_id}`} className="card-body collapse" data-bs-parent="#accordion">
                                                                            <ul className="sidemenu">
                                                                                {
                                                                                    sub_menus ? sub_menus.map((item) => {
                                                                                        if(item.is_active == "0"){
                                                                                        return (
                                                                                            <li key={item.menu_title}>
                                                                                                {
                                                                                                    item.menu_link.includes('http') ?
                                                                                                        <a onClick={(e) => this.remtrid(e)} target="_blank" href={item.menu_link}><span>{item.menu_title}</span></a>
                                                                                                        :
                                                                                                        <NavLink onClick={(e) => this.remtrid(e)} target={linktype === 'true' ? "_blank" : ''} to={item.menu_link}><span>{item.menu_title}</span></NavLink>
                                                                                                }

                                                                                            </li>
                                                                                        )
                                                                                        }
                                                                                    })
                                                                                        : ''
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                    <div className="card-header my-0 collapsed" data-bs-toggle="collapse" data-bs-parent="#accordion" href={`#menu_${menu_id}`} >
                                                                    <p className="card-title ms-0 mb-0">
                                                                    {/* {
                                                                    console.log(menu_icon) }
                                                                    {console.log("menu_icon")
                                                                    } */}
                                                                    {menu_icon ?
                                                                        // <NavLink className="btn btn-header-link p-0" onClick={(e) => this.remtrid(e)}  to={`#`}>{renderHTML(menu_icon)}</NavLink>
                                                                        <>{renderHTML(menu_icon)}</>
                                                                        : ''}

                                                                    <span>{menu_title}</span>
                                                                    </p>
                                                                        </div>
                                                                        <div id={`menu_${menu_id}`} className="card-body p-0 collapse" data-bs-parent="#accordion">
                                                                            <ul className="sidemenu">
                                                                                {
                                                                                    sub_menus ? sub_menus.map((item) => {
                                                                                        if(item.is_active == "0"){
                                                                                        return (
                                                                                            item.menu_link == '/superadmin-profile' ?
                                                                                                this.state.userrole != "$Supa#79+57hDuh%+ad+iN%4My5" ?
                                                                                                    '' :
                                                                                                    <li key={item.menu_title}>
                                                                                                        {
                                                                                                            item.menu_link.includes('http') ?
                                                                                                                <a onClick={(e) => this.remtrid(e)} target="_blank" href={item.menu_link}><span><i className="far fa-circle me-2"></i>{item.menu_title}</span></a>
                                                                                                                :
                                                                                                                <NavLink onClick={(e) => this.remtrid(e)} target={item.linktype === 'true' ? "_blank" : ''} to={item.menu_link}><span>{item.menu_title}</span></NavLink>
                                                                                                        }
                                                                                                    </li>
                                                                                                :
                                                                                                <li key={item.menu_title}>
                                                                                                    {
                                                                                                        item.menu_link.includes('http') ?
                                                                                                            <a onClick={(e) => this.remtrid(e)} target="_blank" href={item.menu_link}><span>{item.menu_title}</span></a>
                                                                                                            :
                                                                                                            <NavLink onClick={(e) => this.remtrid(e)} target={item.linktype === 'true' ? "_blank" : ''} to={item.menu_link}><span>{item.menu_title}</span></NavLink>
                                                                                                    }
                                                                                                </li>

                                                                                        )
                                                                                        }
                                                                                    })
                                                                                        : ''
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                    </>
                                                        )
                                                    }
                                                    })
                                                    : ''
                                            }


                                        </div>
                                        {
                                            // this.props.singleuserdataloading ?
                                            // ""
                                            // :
                                            <div className="learn-more sidenavlogout p-3 my-2">
                                                <div className="">
                                                    <div className="sidenav_user_profile text-center m-auto">
                                                        {
                                                            this.state.profile_pic
                                                                ?
                                                                this.state.profile_pic.includes('http')
                                                                    ?
                                                                    <LazyLoadImage className="img-fluid user-profile-icon" src={this.state.profile_pic} alt="" />
                                                                    :
                                                                    this.state.profile_pic === '' || this.state.profile_pic === null
                                                                        ?
                                                                        <LazyLoadImage className="img-fluid user-profile-icon" src={`${process.env.REACT_APP_GCPURL}/Profile-pic-Activelink.svg`} alt="" />
                                                                        :
                                                                        <LazyLoadImage className="img-fluid user-profile-icon" src={`${process.env.REACT_APP_GCPURL}/profileimages/${this.state.profile_pic}?random=${moment().format('YYYYMM-DDHHmmss')}`} alt="" onError={this.addDefaultSrcprofile} />
                                                                :
                                                                <LazyLoadImage className="img-fluid user-profile-icon" src={`${process.env.REACT_APP_GCPURL}/Profile-pic-Activelink.svg`} alt="" />

                                                        }
                                                    </div>
                                                    <div className="sidenav_user_details float-start w-100 text-center">
                                                        <p className="my-2">{this.state.nickname}</p>
                                                        <NavLink to="/" className="" onClick={(e) => this.logout(e)}><i className="fas fa-power-off"></i></NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </>
                        }


                        {/* <div className="modal fade" id="modal-container-56324199342" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                            <div className="login modal-dialog" role="document">
                                <div className="login modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="myModalLabel">
                                            Image
                                        </h5> 
                                        <button type="button" className="close" data-bs-dismiss="modal">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <img src={this.state.viewprizeimage} alt="tourprize"/>
                                    </div>
                                </div>
                            </div>
                        </div> */}


                    </div>
                </div>

                {/* update homepage banner */}
                <div className="modal success-message add-funds" id="modal-container-updatebanner" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="mb-0">Update Banner</h5>
                                <button type="button" id="" className="close bg-transparent border-0" data-bs-dismiss="modal">×</button>
                            </div>
                            <div className="modal-body text-center mx-1">
                                {/* {
                                this.state.bannerext === 'mp4' || this.state.bannerext === 'webm'?
                                <>
                                    <div className="modal-header text-center">
                                        <h5 className="modal-titl text-center w-100 creategametitle" id="myModalLabel">Video</h5>
                                    </div>
                                    <div className="modal-body create_match">
                                        <video className="desktop" width="100%" height="auto" preload="auto" autoplay loop muted playsinline>
                                            <source src={this.state.banner_image_full} />
                                        </video>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="modal-header text-center">
                                        <h5 className="modal-titl text-center w-100 creategametitle" id="myModalLabel">Image</h5>
                                    </div>
                                    <div className="modal-body create_match">
                                        <img src={this.state.banner_image_full} alt="Banner"/>
                                    </div>
                                </>
                            }   */}
                                <div className="float-start w-100">
                                    <FormGroup className="float-start w-100 mb-3">
                                        <FormLabel htmlFor="title">Banner<div className="tooltippp"><i className="fa fa-info-circle ms-1" aria-hidden="true"></i>
                                            <span className="tooltippptext">Recommended image properties<br />
                                                ● <b>Mobile Dimensions:</b> 675 x 339<br />
                                                ● <b>Desktop Dimensions:</b> 1920 x 269<br />
                                                ● <b>Size:</b> less than 5 MB<br />
                                                ● <b>Format:</b> .jpg, .gif, or .png</span>
                                        </div>
                                        </FormLabel>
                                        <div className="form-group w-100 mb-3">
                                            <div className="w-75 registered text-center upload-file">
                                                <label htmlFor="bannerimagee" className="choose_file">
                                                    
                                                    {this.state.bannerimagefile && this.state.bannerimagefile != "" ?

                                                        <span>
                                                            {
                                                                this.state.bannerext === 'mp4' || this.state.bannerext === 'webm' ?
                                                                    <video className="desktop" width="100%" height="auto" preload="auto" autoplay loop muted playsinline>
                                                                        <source src={this.state.bannerimagefile} />
                                                                    </video>
                                                                    :
                                                                    <img src={this.state.bannerimagefile} alt="Banner" />
                                                            }
                                                        </span>
                                                        :
                                                        <span>
                                                            {
                                                                this.state.bannerext === 'mp4' || this.state.bannerext === 'webm' ?
                                                                    <video className="desktop" width="100%" height="auto" preload="auto" autoplay loop muted playsinline>
                                                                        <source src={this.state.banner_image_full} />
                                                                    </video>
                                                                    :
                                                                    <img className="dynamicimages_sidebar w-100" src={this.state.banner_image_full} />
                                                            }

                                                        </span>
                                                    }
                                                   
                                                    

                                                </label>
                                                <input id="bannerimagee" className="hide-input" type="file" name="bannerimagee" onChange={e => this.dataChange(e)} />
                                            </div>
                                            {this.tourValidatorhomebanner.message('Image', this.state.bannerimagee, 'required')}
                                        </div>
                                        <br />
                                    </FormGroup>
                                    <FormGroup className="w-100 mb-3">
                                        <FormLabel htmlFor="title">Banner Url</FormLabel>
                                        <br />
                                        <FormControl type="text" maxLength="35" name="banner_link" value={this.state.banner_link} onChange={e => this.dataChange(e)} />
                                    </FormGroup>
                                    <FormGroup className="w-100 mb-3">
                                        <FormLabel htmlFor="title">Screen Type</FormLabel>
                                        <br />
                                        <select className="form-control" name="screentype" value={this.state.screentype} onChange={e => this.dataChange(e)}>
                                            <option value="">Screen Type?</option>
                                            <option value="video">Video</option>
                                            <option value="image">Image</option>
                                        </select>
                                        {this.tourValidatorhomebanner.message('screentype', this.state.screentype, 'required')}
                                    </FormGroup>
                                    {/* {
                                        console.log(this.state.banner_link, this.state.screentype, this.state.bannertype, this.state.bannertext)
                                    } */}
                                    <FormGroup className="w-100 mb-3">
                                        <FormLabel htmlFor="title">Banner Type</FormLabel>
                                        <br />
                                        <select className="form-control" name="bannertype" value={this.state.bannertype} onChange={e => this.dataChange(e)}>
                                            <option value="">Banner Type?</option>
                                            <option value="desktop">Desktop</option>
                                            <option value="mobile">Mobile</option>
                                        </select>
                                        {this.tourValidatorhomebanner.message('bannertype', this.state.bannertype, 'required')}
                                    </FormGroup>
                                    {/* <FormGroup className="w-100">
                                        <FormLabel htmlFor="title">Banner Text</FormLabel>
                                        <br />
                                        <FormControl type="text" maxLength="35" name="bannertext" value={this.state.bannertext} onChange={e => this.dataChange(e)} />
                                    </FormGroup> */}
                                    <div className="editdiv float-start w-100">
                                        <button type="submit" onClick={(e) => this.updateBanner(e, '756753335', 'home')} disabled={this.state.isSubmithomebanner} className="pull-left cfh_button">{this.state.isSubmithomebanner ? 'Please wait' : 'Update'}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal success-message add-funds" id="modal-container-updatepartner" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="mb-0">Update Partner</h5>
                                <button type="button" id="close_updatepartner" className="close bg-transparent border-0" data-bs-dismiss="modal">×</button>
                            </div>
                            <div className="modal-body text-center mx-1">
                                <div className="float-start w-100 mt-0">
                                    <div className="form-group float-start w-100 mb-3">
                                        <div className="registered text-center upload-file">
                                            <label htmlFor="partnerimagee" className="choose_file">
                                                {this.state.partnerimageefile && this.state.partnerimageefile != "" ?

                                                    <span><img src={this.state.partnerimageefile} /></span>
                                                    :
                                                    <span><img className="dynamicimages_sidebar w-100" src={this.state.partner_image_full} /></span>
                                                }
                                            </label>
                                            <input id="partnerimagee" className="hide-input" type="file" name="partnerimagee" onChange={e => this.dataChange(e)} />
                                        </div>

                                        {/* <img src={this.state.partner_image_full} alt="partner image"/>
                                        <label className="float-start">Image Link </label>
                                        <input className="form-control" type="text" name="image_name" value={this.state.image_name} onChange={(e) => this.dataChange(e)} /> */}
                                        {this.Validatorhomepartner.message('Partner Image', this.state.partner_image_full, 'required')}
                                    </div>
                                    <div className="form-group w-100 mb-3">
                                        <label className="form-label">Link Title</label>
                                        <input className="form-control" type="text" name="title" value={this.state.title} onChange={(e) => this.dataChange(e)} />
                                        {this.Validatorhomepartner.message('Title', this.state.title, 'required')}
                                    </div>
                                    <div className="form-group w-100 mb-3">
                                        <label className="form-label">Link Url</label>
                                        <input className="form-control" type="text" name="linkurl" value={this.state.linkurl} onChange={(e) => this.dataChange(e)} />
                                        {this.Validatorhomepartner.message('Url', this.state.linkurl, 'required')}
                                    </div>
                                    {/* <div className="form-group w-100 mb-3">
                                        <label className="form-label">Description</label>
                                        <input className="form-control" type="text" name="description" value={this.state.description} onChange={(e) => this.dataChange(e)} />
                                        {this.Validatorhomepartner.message('Title', this.state.description, 'required')}
                                    </div>   */}
                                    <div className="form-group w-100 mb-3">
                                        <label className="form-label">Active Status</label>
                                        <select className="form-control" value={this.state.is_active} name="is_active" onChange={e => this.dataChange(e)}>
                                            <option value="0">Active</option>
                                            <option value="1">Inactive</option>
                                        </select>
                                        {this.Validatorhomepartner.message('Status', this.state.is_active, 'required')}
                                    </div>

                                    <div className="editdiv float-start w-100">
                                        <input type="submit" name="edittour" className="pull-left cfh_button" disabled={this.state.isSubmitcred} onClick={(e) => this.updatepartner(e)} value={this.state.isSubmitcred ? 'Please wait' : 'Update'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}
const mapStateToProps = (state) => ({
    adminmenuloding: state.menu.adminmenuloding,
    allmenusadmindata: state.menu.allAdminMenus,

    singleuserdata: state.userDetails.singleuser,
    singleuserdataloading: state.userDetails.loading,

    secureclientdata: state.dynamic.secureclientdata,
    secureclientdataloading: state.dynamic.secureclientdataloading,

    desktopbanner: state.banners.desktopbanner,
    mobilesingbanner: state.banners.mobilesingbanner,
    desktopbannerloading: state.banners.loading,
    logs: state.dynamic.logs
})
// export default withRouter(AdminSideNavBar);
export default connect(mapStateToProps)(AdminSideNavBar);


