import React, { Component } from 'react';
// import Header from './Header';
// import Footer from './Footer';
class CompleteEmptyLayout extends Component {
    render() {
        return (
            <>
              {/* <Header/> */}
            <div className="login-body-wrapper login-body-login">
                {this.props.children}
            </div>
            {/* <Footer/> */}
            </>
        );
    }
}

export default CompleteEmptyLayout;