import * as types from "../types/types";

const initalState = {
    loading: true,
    loadingone:true,
    data: [],
    getmessage:[],
    sendmessage:[]
}

const messageReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.FETCH_DATA_BEGIN:
            return {
                ...state,
                loading: true
            }
            case types.FETCH_DATA_BEGIN_EMAIL:
                return {
                    ...state,
                    loadingone: true
                }
        case types.SEND_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
            case types.GET_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                getmessage: action.payload
            }
            case types.SEND_MESSAGE_EMAIL_SUCCESS:
                return {
                    ...state,
                    loadingone: false,
                    sendmessage: action.payload
                }
               
        default:
            return state
    }
}

export default messageReducer;