  
 import * as types from "../types/types";

 const initalState = {
     loading: true,
     data: [],
     singlegamedata: [],
     alldbgames: [],
     allcomingsoongames: [],
     customhomepagegame:[],
     customhomepageleadergame:[],
     allgamecategory:[],
     allfiltergamebycategory:[],
     allgamertaguser:[],
 }
 
 const gamesReducer = (state = initalState, action) => {
     switch (action.type) {
         case types.FETCH_GAMES_BEGIN:
             return {
                 ...state,
                 loading: true
             }
         case types.FETCH_GAMES_SUCCESS:
             return {
                 ...state,
                 loading: false,
                 data: action.payload
             }
             case types.FETCH_DB_GAMES_SUCCESS:
                 return {
                     ...state,
                     loading: false,
                     alldbgames: action.payload
                 }
             case types.FETCH_SINGLE_GAME_SUCCESS:
                 
                 return {
                     ...state,
                     loading: false,
                     singlegamedata: action.payload
                 }
                 case types.FETCH_COMINGSOON_GAMES_SUCCESS:
                 
                 return {
                     ...state,
                     loading: false,
                     allcomingsoongames: action.payload
                 }
 
         case types.FETCH_HOMEPAGE_GAMES_SUCCESS:
             return {
                 ...state,
                 loading: false,
                 customhomepagegame: action.payload
             }
         case types.FETCH_HOMEPAGE_LEADER_GAMES_SUCCESS:
             return {
                 ...state,
                 loading: false,
                 customhomepageleadergame: action.payload
             }
         case types.FETCH_HOMEPAGE_REGISTER_IMAGE_SUCCESS:
             return {
                 ...state,
                 loading : false,
                 customhomepageregisterimages: action.payload
             }
         case types.FETCH_ALL_GAME_CATEGORY_SUCCESS:
             return {
                 ...state,
                 loading : false,
                 allgamecategory: action.payload
             }   
         case types.FETCH_ALL_FILTER_GAME_BY_CATEGORY_SUCCESS:
             return {
                 ...state,
                 loading : false,
                 allfiltergamebycategory: action.payload
             }              
         case types.FETCH_ALL_GAMERTAG_USER:
             return {
                 ...state,
                 loading : false,
                 allgamertaguser: action.payload
             }
         default:
             return state
     }
 }
 
 export default gamesReducer;