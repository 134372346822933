  
 import * as types from "../types/types";

 const initalState = {
     loading: true,
     allbanners: [],
     singbanner: [],

     desktopbanner: [],
     mobilesingbanner: [],
     singhomebanner: [],
 }
 
 const bannerReducer = (state = initalState, action) => {

     switch (action.type) {
         case types.FETCH_BANNERS_BEGIN:
             return {
                 ...state,
                 loading: true
             }
         case types.FETCH_BANNERS_SUCCESS:
             return {
                 ...state,
                 loading: false,
                 allbanners: action.payload
             }
             case types.FETCH_SINGLE_BANNERS_SUCCESS:
             return {
                 ...state,
                 loading: false,
                 singbanner: action.payload
             }
             case types.FETCH_DESK_BANNERS_SUCCESS:
             return {
                 ...state,
                 loading: false,
                 desktopbanner: action.payload
             }
             case types.FETCH_MOB_BANNERS_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    mobilesingbanner: action.payload
                }
                case types.FETCH_HOME_BANNER_SUCCESS:
                    return {
                        ...state,
                        loading: false,
                        singhomebanner: action.payload
                    }
             
         default:
             return state
     }
 }
 
 export default bannerReducer;