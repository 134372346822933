import * as types from "../types/types";

const initalState = {
    loading: true,
    alltoursloading: true,
    activetoursloading: true,
    data: [],
    matchdata: [],
    tourdata: [],
    singletourdata:[],
    filteredtours:[],
    activetiurdata:[],
    filteredalltours:[],
    alltournamentsloader: true,
    alltournamentsdata: [],
    redeemrewardlist:[],
    alljoinedteams:[],
    archivedtourloading:true,
    allarchivedtour:[],
    allsingletournamentseries:[],
    seriesgame:[],
    getallplatformvariants:[],
    getalldrafttour:[],
    getallsorttour:[],
    getallsortdrafttour:[],
    getallsortarchivedtour:[],
    getalltourcomm:[],
    getalltourseries:[],
}

const tournamentsReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.FETCH_DATA_BEGIN:
            return {
                ...state,
                loading: true,
            }
            case types.FETCH_DATA_BEGIN_ALL_TOURS:
            return {
                ...state,
                alltournamentsloader: true,
            }
            case types.FETCH_DATA_BEGIN_SINGLE_TOUR:
                return {
                    ...state,
                alltoursloading: true,
                }
        case types.FETCH_DATA_BEGIN_ARCHIVED_TOUR:
            return {
                ...state,
                archivedtourloading: true,
            }  
        case types.FETCH_ARCHIVED_TOURNAMENTS_SUCCESS:
        return {
            ...state,
            archivedtourloading:false,
            data: action.payload
        }           
        case types.FETCH_DATA_BEGIN_ALL_TOUR:
                return {
                    ...state,
                activetoursloading: true,
                }
                case types.FETCH_ALL_TOURNAMENTS_SUCCESS:
            return {
                ...state,
                activetoursloading:false,
                activetiurdata: action.payload
            } 
        case types.FETCH_ALL_TOURS_SUCCESS:
            return {
                ...state,
                alltournamentsloader:false,
                alltournamentsdata: action.payload
            }        
        case types.FETCH_TOURNAMENTS_SUCCESS:
            return {
                ...state,
                alltoursloading:false,
                data: action.payload
            }
            case types.FETCH_FILTER_TOURNAMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
            case types.FETCH_ALL_TOURNAMENTS_MATCHES:
            return {
                ...state,
                loading: false,
                matchdata: action.payload,
                tourdata: action.payloadd
            }
            case types.FETCH_SINGLE_TOURNAMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                singletourdata: action.payload,
            }
            case types.FETCH_FILTERED_TOURNAMENTS:
                return {
                    ...state,
                    loading: false,
                    filteredtours: action.payload,
                }

            case types.FETCH_FILTERED_ALL_TOURNAMENTS:
            return {
                ...state,
                loading: false,
                filteredalltours: action.payload,
            }
            case types.FETCH_REDEEM_REWARDS_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    redeemrewardlist: action.payload,
                }
            case types.FETCH_EXTERNALURL_JOINED_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    externaljoinedlist: action.payload,
                }
            case types.FETCH_ALL_JOINED_TEAMS_DATA_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    alljoinedteams: action.payload,
                }
            case types.FETCH_DRAFT_TOURNAMENT:
                return {
                    ...state,
                    loading: false,
                    alldrafttournament: action.payload,
                }
            case types.FETCH_TOURNAMENT_SERIES:
                return{
                    ...state,
                    loading: false,
                    alltournamentseries: action.payload,
                }
            case types.FETCH_SINGLE_TOURNAMENT_SERIES:
                return{
                    ...state,
                    loading: false,
                    allsingletournamentseries: action.payload,
                    tourname: action.payloadd,
                    seriesgame: action.payloaddd
                }
            case types.FETCH_PLATFORM_VARIANTS:
                return{
                    ...state,
                    loading: false,
                    allplatformvariants: action.payload,
                }
            case types.FETCH_ALL_PLATFORM_VARIANTS:
                return{
                    ...state,
                    loading: false,
                    getallplatformvariants: action.payload,
                }  
            case types.FETCH_ALL_DRAFT_TOURNAMENT:
                return{
                    ...state,
                    loading: false,
                    getalldrafttour: action.payload,
                } 
            case types.FETCH_ALL_SORT_TOURNAMENT:
                return{
                    ...state,
                    loading: false,
                    getallsorttour: action.payload,
                }  
            case types.FETCH_ALL_SORT_DRAFT_TOURNAMENT:
                return{
                    ...state,
                    loading: false,
                    getallsortdrafttour: action.payload,
                }  
            case types.FETCH_ALL_SORT_ARCHIVED_TOURNAMENT:
                return{
                    ...state,
                    loading: false,
                    getallsortarchivedtour: action.payload,
                }  
            case types.FETCH_ALL_TOURS_COMM_SUCCESS:
                return{
                    ...state,
                    loading: false,
                    getalltourcomm: action.payload,
                }
            case types.FETCH_ALL_TOURS_SERIES_SUCCESS:
                return{
                    ...state,
                    loading: false,
                    getalltourseries: action.payload,
                }     
                
        default:
            return state
    }
}

export default tournamentsReducer;