import { apiBaseUrl} from '../helpers/common';
import * as actionTypes from '../types/types';
import {handleResponse} from '../helpers/userServices';

export const fetchlobbyStart = () => {
    return {
        type: actionTypes.FETCH_LOBBY_BANNER_BEGIN
    }
}

export const fetchStartSstream=()=>{
    return{
        type:actionTypes.FETCH_STREAM_BEGIN
    }
}
export const fetchsinglestreamSuccess=(data)=>{
    return{
        type:actionTypes.FETCH_STREAM_SUCCESS,
        payload:data.data
    }
}

export const fetchallstreamdata=(data)=>{
    return{
        type:actionTypes.FETCH_ALL_STRAM_DATA_SUCCESS,
        payload:data.data
    }
}

export const fetchlobbyDeskbannerSuccess = (data) => {
    return {
        type: actionTypes.FETCH_LOBBY_DESK_BANNER_SUCCESS,
        payload: data.data
    }
}
export const fetchlobbyMobbannerSuccess = (data) => {
    return {
        type: actionTypes.FETCH_LOBBY_MOB_BANNER_SUCCESS,
        payload: data.data
    }
}

export const fetchalllobbydatas=(data)=>{
    return{
        type:actionTypes.FETCH_LOBBY_ALL_DATA_SUCCESS,
        payload:data.data
    }
}


export const fetchlobbyHomebannerSuccess = (data) => {
    return {
        type: actionTypes.FETCH_LOBBY_HOME_BANNER_SUCCESS,
        payload: data.data
    }
}

export function getlobbyDeskbanners(slug='lobby') {
    return dispatch => {
        dispatch(fetchlobbyStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-desk-lobbybanner/${slug}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchlobbyDeskbannerSuccess(data))
        });
    }
}

export function getlobbyMobbanner(slug='lobby') {
    return dispatch => {
        dispatch(fetchlobbyStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-mob-lobbybanner/${slug}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' , 'X-Auth-Token': `${localStorage.getItem('token')}`}),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchlobbyMobbannerSuccess(data))
        });
    }
}


export function getlobbyalldata(slug='lobby', pagenum=1) {
    return dispatch => {
        dispatch(fetchlobbyStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-lobby-all-data/${slug}/${pagenum}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' , 'X-Auth-Token': `${localStorage.getItem('token')}`}),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchalllobbydatas(data))
        });
    }
}

export function getSinglestream(id, idd = `${process.env.REACT_APP_PLATFORM_SUFFIX}`) {
    return dispatch => {
        dispatch(fetchStartSstream());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-single-stream/${id}/${idd}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchsinglestreamSuccess(data))
        });
    }
}


export function getallstreamdata(id = `${process.env.REACT_APP_PLATFORM_SUFFIX}`,pagenum=1) {
    return dispatch => {
        dispatch(fetchStartSstream());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-all-stream/${id}/${pagenum}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchallstreamdata(data));
        });
    }
}

