import * as types from "../types/types";

const initalState = {
    categoriesloading: false,
    categories: [],
    productsloading: false,
    products: [],
    searchproductsloading: false,
    searchproducts: [],
    productloading: false,
    featuredproducts: [],
    featuredproductloading: false,
    product: [],
    parentcategoriesloading: false,
    parentcategories: [],
    getProducts:[],
    variationgroup:[],
    variationgrouploading:false,
    variation:[],
    variationloading:false,
    groupedvariation:[],
    groupedvariationloading:false,
    cartdata:[],
    cartdataloading:false,
    addressesdata:[],
    addressesloading:false,
    categoryproducts:[],
    categoryproductsloading:false,
    ecommerceordersloading:false,
    ecommerceorders:[],
    orderloading:false,
    order:[],
    adminordersloading:false,
    adminorders:[],
    couponsloading:false,
    coupons:[],
    cartdatayid:[],
    settings:[],
    allvariation: [],
    settingsloading:false
}

const matchReducer = (state = initalState, action) => {
    switch (action.type) {
       
        case types.FETCH_CATEGORIES_START:
            return {
                ...state,
                categoriesloading: true
            }
        case types.FETCH_CATEGORIES_SUCCESS:
            return {
                
                ...state,
                categoriesloading: false,
                categories: action.payload
            }
        case types.FETCH_PARENT_CATEGORIES_START:
            return {
                ...state,
                parentcategoriesloading: true
            }
        case types.FETCH_PARENT_CATEGORIES_SUCCESS:
            return {
                
                ...state,
                parentcategoriesloading: false,
                parentcategories: action.payload
            }
        case types.FETCH_PRODUCTS_START:
            return {
                ...state,
                productsloading: true,
                
            }
        case types.FETCH_PRODUCTS_SUCCESS:
            return {
                ...state,
                productsloading: false,
                products: action.payload
            }
        case types.FETCH_SEARCHED_PRODUCTS_START:
        return {
            ...state,
            searchproductsloading: true
        }
        case types.FETCH_SEARCHED_PRODUCTS_SUCCESS:
        return {
            ...state,
            searchproductsloading: false,
            searchproducts: action.payload
        }
        case types.FETCH_SHOP_SETTINGS_START:
            return {
                ...state,
                settingsloading: true
            }
        case types.FETCH_SHOP_SETTINGS_SUCCESS:
            return {
                ...state,
                settingsloading: false,
                settings: action.payload
            }
        case types.FETCH_FEATURED_PRODUCTS_START:
            return {
                ...state,
                featuredproductloading: true
            }
        case types.FETCH_FEATURED_PRODUCTS_SUCCESS:
            return {
                ...state,
                featuredproductloading: false,
                featuredproducts: action.payload
            }
        case types.FETCH_PRODUCT_START:
            return {
                ...state,
                productloading: true
            }
        case types.FETCH_PRODUCT_SUCCESS:
            return {
                ...state,
                productloading: false,
                product: action.payload
            }

        case types.FETCH_VARIATION_GROUP_START:
            return {
                ...state,
                variationgrouploading: true
            }
        case types.FETCH_VARIATION_GROUP_SUCCESS:
            return {
                ...state,
                variationgrouploading: false,
                variationgroup: action.payload
            }
        case types.FETCH_ALL_VARIATION_SUCCESS:
            return {
                ...state,
                variationgrouploading: false,
                allvariation: action.payload
            }
            
        case types.FETCH_VARIATION_START:
            return {
                ...state,
                variationloading: true
            }
        case types.FETCH_VARIATION_SUCCESS:
            return {
                ...state,
                variationloading: false,
                variation: action.payload
            }
        case types.FETCH_GROUPED_VARIATION_START:
            return {
                ...state,
                groupedvariationloading: true
            }
        case types.FETCH_GROUPED_VARIATION_SUCCESS:
            return {
                ...state,
                groupedvariationloading: false,
                groupedvariation: action.payload
            }
        case types.FETCH_CART_START:
            return {
                ...state,
                cartdataloading: true
            }
        case types.FETCH_CART_SUCCESS:
            return {
                ...state,
                cartdataloading: false,
                cartdata: action.payload
            }

        // case types.FETCH_CART_CARTID_SUCCESS:
        //     return {
        //         ...state,
        //         cartdataloading: false,
        //         cartdatayid: action.payload
        //     }
        case types.FETCH_ADDRESSES_START:
            return {
                ...state,
                addressesloading: true
            }
        case types.FETCH_ADDRESSES_SUCCESS:
            return {
                ...state,
                addressesloading: false,
                addressesdata: action.payload
            }
        case types.FETCH_CATEGORY_PRODUCT_START:
            return {
                ...state,
                categoryproductsloading: true
            }
        case types.FETCH_CATEGORY_PRODUCT_SUCCESS:
            return {
                ...state,
                categoryproductsloading: false,
                categoryproducts: action.payload
            }        
        case types.FETCH_USER_ORDERS_START:
            return {
                ...state,
                ecommerceordersloading: true
            }
        case types.FETCH_USER_ORDERS_SUCCESS:
            return {
                ...state,
                ecommerceordersloading: false,
                ecommerceorders: action.payload
            }
        case types.FETCH_ORDERS_START:
            return {
                ...state,
                adminordersloading: true
            }
        case types.FETCH_ORDERS_SUCCESS:
            return {
                ...state,
                adminordersloading: false,
                adminorders: action.payload
            }
        case types.FETCH_SINGLE_USER_ORDER_START:
            return {
                ...state,
                orderloading: true
            }
        case types.FETCH_SINGLE_USER_ORDER_SUCCESS:
            return {
                ...state,
                orderloading: false,
                order: action.payload
            } 
        case types.FETCH_COUPONS_START:
            return {
                ...state,
                couponsloading: true
            }
        case types.FETCH_COUPONS_SUCCESS:
            return {
                ...state,
                couponsloading: false,
                coupons: action.payload
            }        
        default:
            return state
    }
}

export default matchReducer;