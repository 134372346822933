import React from 'react';
import swal from 'sweetalert';

import toastr from "reactjs-toastr";
import axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment';
import LinearProgress from "@material-ui/core/LinearProgress";



export const apiBaseUrl = `${process.env.REACT_APP_APIURL}`;
export const logo_dark = `${process.env.REACT_APP_GCPURL}/xr-header/Logo_dark.png`;
export const logo_light = `${process.env.REACT_APP_GCPURL}/xr-header/Logo_light.png`;
export const loaderspinner = `${process.env.REACT_APP_GCPURL}/xr-header/ActiveLinkLoader.gif`;
export const banner_loader = `${process.env.REACT_APP_GCPURL}/xr-header/banner_loader.gif`;
export const admin_loaderspinner = `${process.env.REACT_APP_S3BUCKET_COMMON}/xradminloader/XRDynamicLoader.gif?random=qwaaQXSW`;
export const apple_icon = `${process.env.REACT_APP_GCPURL}/mav-header/apple_icon.jpg?random=qwaaQSDF`;
export const banner_presenting = `${process.env.REACT_APP_GCPURL}/banners/presentingpartner.png?random=qwaaaQQ`;
export const favicon_icon = `${process.env.REACT_APP_GCPURL}/mav-header/favicon.jpg?random=qwaaQSDF`;


export function LinearProgress_loader() {  
                return (<div className="loaderdiv-linear">
                  <LinearProgress color="secondary" />
                </div>); 
}


export const deleteRecord = (url,id)=>{
  return new Promise((resolve,reject)=>{
      const request = new Request(`${process.env.REACT_APP_APIURL}/user/${url}/${id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": `${localStorage.getItem("token")}`
          }
        });
      fetch(request)
      .then(res => res.json())
      .then(res => {
        // window.location.reload();
        if (res.ResponseCode === "1") {
          // swal("Done", res.ResponseText, "success");
          swalpopup(res.ResponseText, "success");
          resolve(res);
        } else {
          swalpopup(res.ResponseText, "error");
          // swal("Oops!", res.ResponseText, "error");
          reject(Error("Something went wrong"));
        }
      });
  })
}
// Ecommerce Commons
export const addToCart = (url,product_id,quantity)=>{
  let user_id = '';
  if(!localStorage.getItem("token")){
    swalpopup('You need to login first.', "error");
    // swal("Oops", 'You need to login first.', "error");
    return;
  }else{
    const currdetails = parseJwt(localStorage.getItem('token'));
    user_id = currdetails.sub;
    return new Promise((resolve,reject)=>{
      const request = new Request(`${process.env.REACT_APP_APIURL}/user/${url}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": `${localStorage.getItem("token")}`
          },
          body: JSON.stringify({
            product_id,
            user_id,
            quantity
          })
        });
        fetch(request)
        .then(res => res.json())
        .then(res => {
          // swal("Done", res.ResponseText, "success");
          swalpopup(res.ResponseText, "success");
        });
    })
  }
}
export const updateCart = (url,cart_product_id,quantity)=>{
  let user_id = '';
  if(!localStorage.getItem("token")){
    // swal("Oops", 'You need to login first.', "error");
    swalpopup('You need to login first.', "error");

    return;
  }else{
    const currdetails = parseJwt(localStorage.getItem('token'));
    user_id = currdetails.sub;
    return new Promise((resolve,reject)=>{
      const request = new Request(`${process.env.REACT_APP_APIURL}/user/${url}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": `${localStorage.getItem("token")}`
          },
          body: JSON.stringify({
            cart_product_id,
            user_id,
            quantity
          })
        });
        fetch(request)
        .then(res => res.json())
        .then(res => {
          // swal("Done", res.ResponseText, "success");
          swalpopup(res.ResponseText, "success");

        });
    })
  }
}
// Ecommerce Commons

export const deleteRecordwithID = (url,id,idd)=>{
return new Promise((resolve,reject)=>{
    const request = new Request(`${process.env.REACT_APP_APIURL}/user/${url}/${id}/${idd}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": `${localStorage.getItem("token")}`
        }
      });
    fetch(request)
    .then(res => res.json())
    .then(res => {
        
      if (res.ResponseCode === "1") {
        // swal("Done", "deleted successfully.", "success");
        swalpopup("Deleted successfully.", "success");
        //  window.location.reload();
         resolve(res);
      } else {
        // toastr.error(res.ResponseText, { displayDuration: 1500 });
        // swal("Oops!", res.ResponseText, "error");
        swalpopup(res.ResponseText, "error");
        //  reject(Error("Something went wrong"));
      }
    });
})
}

// export default function logout_session(token) {
// export const logout_session = (token) => {

//   if (!token) { return; }
//     localStorage.clear('token');
//     this.setState({ isLoggedIn: !this.state.isLoggedIn, });
//     this.props.history.push('/');
//     // window.location.reload();
//   }

export default function parseJwt(token) {
if (!token) { return; }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}
export function mapname(map){
if (map === "dust2") {
  return 'Dust 2';
}else if(map === 'inferno'){
  return 'Inferno';
}else if(map === 'mirage'){
  return 'Mirage';
}else if(map === 'nuke'){
  return 'Nuke';
}else if(map === 'overpass'){
  return 'Overpass';
}else if(map === 'train'){
  return 'Train';
}else if(map === 'vertigo'){
  return 'Vertigo';
}else if(map === 'battleroyaleisland'){
  return 'Battle Royale Island';
}else if(map === 'sanhok'){
  return 'Sanhok';
}else if(map === 'miramar'){
  return 'Miramar';
}else if(map === 'erangel'){
  return 'Erangel';
}else if(map === 'frogisle'){
  return 'Frog Isle';
}else if(map === 'jaguarfalls'){
  return 'Jaguar Falls';
}else if(map === 'serpentbeach'){
  return 'Serpent Beach';
}else if(map === 'fishmarket'){
  return 'Fish Market';
}else if(map === 'timbermill'){
  return 'Timber Mill';
}else if(map === 'frozenguard'){
  return 'Frozen Guard';
}else if(map === 'icemines'){
  return 'Ice Mines';
}else if(map === 'outpost'){
  return 'Outpost';
}else if(map === 'hidden temple'){
  return 'Hidden Temple';
}else if(map === 'frostbitecaverns'){
  return 'Frostbite Caverns';
}else if(map === 'lylatcruise'){
  return 'Lylat Cruise';
}else if(map === 'pokemonstadium2'){
  return 'Pokemon Stadium 2';
}else if(map === 'smashville'){
  return 'Smashville';
}else if(map === 'castlesiege'){
  return 'Castle Siege';
}else if(map === 'kalospokemonleague'){
  return 'Kalos Pokemon League';
}else if(map === 'towncity'){
  return 'Town & City';
}else if(map === 'unovapokemonleague'){
  return 'Unova Pokemon League';
}else if(map === 'yoshisIsland'){
  return 'Yoshi’s Island (Brawl)';
}else if(map === 'yoshisstory'){
  return 'Yoshi’s Story';
}else if(map === 'finaldestination'){
  return 'Final Destination';
}else if(map === 'arenaferox'){
  return 'Arena Ferox';
}else if(map === 'battlefield'){
  return 'Battlefield';
}else if(map === 'castlesiege'){
  return 'Castle Siege';
}else if(map === 'corneria'){
  return 'Corneria';
}else if(map === 'delfinoplaza'){
  return 'Delfino Plaza';
}else if(map === 'dreamland'){
  return 'Dream Land';
}else if(map === 'frigateorpheum'){
  return 'Frigate Orpheum';
}else if(map === 'greatplateautower'){
  return 'Great Plateau Tower';
}else if(map === 'halberd'){
  return 'Halberd';
}else if(map === 'kalospokémonleague'){
  return 'Kalos Pokémon League';
}else if(map === 'kongofalls'){
  return 'Kongo Falls';
}else if(map === 'kongojungle'){
  return 'Kongo Jungle';
}else if(map === 'lylatcruise'){
  return 'Lylat Cruise';
}else if(map === 'moraytowers'){
  return 'Moray Towers';
}else if(map === 'newdonkcityhall'){
  return 'New Donk City Hall';
}else if(map === 'peachscastle'){
  return "Peach’s Castle";
}else if(map === 'pokémonstadium'){
  return 'Pokémon Stadium';
}else if(map === 'pokémonstadium2'){
  return 'Pokémon Stadium 2';
}else if(map === 'resetbombforest'){
  return 'Reset Bomb Forest';
}else if(map === 'skyworld'){
  return 'Skyworld';
}else if(map === 'smashville'){
  return 'Smashville';
}else if(map === 'superhappytree'){
  return 'Super Happy Tree';
}else if(map === 'suzakucastle'){
  return 'Suzaku Castle';
}else if(map === 'towncity'){
  return 'Town & City';
}else if(map === 'umbraclocktower'){
  return 'Umbra Clock Tower';
}else if(map === 'unovapokemonleague'){
  return 'Unova Pokemon League';
}else if(map === 'venom'){
  return 'Venom';
}else if(map === 'summonersrift'){
  return 'Summoners Rift';
}else if(map === 'dfhstadium'){
  return 'DFH Stadium';
}else if(map === 'hometeamstadium'){
  return 'Home Team Stadium';
}else if(map === 'bind'){
  return 'Bind';
}else if(map === 'haven'){
  return 'Haven';
}else if(map === 'split'){
  return 'Split';
}else if(map === 'ascent'){
  return 'Ascent';
}
else {return '';}
}
export function gameName(gname) {
if (gname == "csgo") {
  return 'Counter-Strike:Global Offensive';
} else if (gname == "overwatch") {
  return 'Overwatch';
} else if (gname == "paladins") {
  return 'Paladins';
} else if (gname == "valorant") {
  return 'Valorant';
} else if (gname == 'fortnite') {
  return 'FORTNITE';
}else if (gname == "supersmash") {
  return 'Super Smash Bros';
} else if (gname == "rocketleague") {
  return 'Rocket League';
} else if (gname == "legends") {
  return 'League of Legends';
}else if (gname == "madden") {
  return 'Madden 21';
} else if (gname == "nba20") {
  return 'NBA 2K21';
} else if (gname == "codmw") {
  return 'Call of Duty Modern Warfare';
}  else if (gname == "codwz") {
  return 'Call of Duty Warzone';
} else if (gname == "codcw") {
  return 'Call of Duty Cold War';
} else if (gname == "mortal") {
  return 'Mortal Kombat 11';
} else if (gname == "fallguys") {
  return 'Fall Guys';
} else if (gname == "fifa21") {
  return 'FiFA 21';
}  else if (gname == "deadbydaylight") {
  return `Dead by Daylight mobile`;
} else if (gname == "f1") {
  return `F1 2021`;
} else if (gname == "legends") {
  return `League of Legends`;
} else if (gname == "minecraft") {
  return `Minecraft`;
} else if (gname == "mobilelegends") {
  return `Mobile Legends`;
} else if (gname == "mortal") {
  return `Mortal Kombat`;
} else if (gname == "paladins") {
  return `Paladins mobile`;
} else if (gname == "pga") {
  return `PGA Golf 2k21`;
} else if (gname == "pubg") {
  return `PUBG`;
} else if (gname == "tetris") {
  return `Tetris`;
} else if (gname == "vallient") {
  return `Valiant`;
}else if (gname == "fifa21") {
  return `FIFA 21`;
}  else if (gname == "rocketleague") {
  return `Rocket League`;
}  else if(gname == "overwatch"){
  return `OVERWATCH`;
} else if (gname == "halo") {
  return `Halo`;
}  else if (gname == "rainbow") {
  return `Rainbow Six`;
} else if (gname == "mario") {
  return `Mario Kart`;
}else {
  return gname;
}

}
// -----------------------game colored icons--------------------------
export function coloredgameicons(gname) {
  if (gname === "csgo") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/common/coloredtourgameimages/CSGO-Logo.png`;
  } else if (gname === "overwatch") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/common/coloredtourgameimages/Overwatch.png`;
  } else if (gname === "paladins") {
    return `Paladins`;
  } else if (gname === "valorant") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/common/coloredtourgameimages/valorant.png`;
  } else if (gname === "fortnite") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/common/coloredtourgameimages/fortnite.png`;
  }else if (gname === "supersmash") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/common/coloredtourgameimages/Super Smash Bros.png`;
  } else if (gname === "rocketleague") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/common/coloredtourgameimages/rocket-league.png`;
  } else if (gname === "legends") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/common/coloredtourgameimages/lol-logo-38475.png`;
  }else if (gname === "madden") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/common/coloredtourgameimages/Madden 21 Logo.png`;
  } else if (gname === "nba20") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/common/coloredtourgameimages/NBA 2K21.png`;
  } else if (gname === "codmw") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/common/coloredtourgameimages/COD-MW.png`;
  } else if (gname === "codcw") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/common/coloredtourgameimages/CoD - Cold War.png`;
  } else if (gname === "mortal") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/common/coloredtourgameimages/mortal kombat.png`;
  } else if (gname === "nascar") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/common/coloredtourgameimages/Nascar Heat 5.png`;
  } else if (gname === "fifa21") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/common/coloredtourgameimages/FIFA 21.png`;
  }  else if (gname === "rainbow") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/common/coloredtourgameimages/Rainbow Six.png`;
  }  else if (gname === "pubg") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/common/coloredtourgameimages/PUBG.png`;
  }  else if (gname === "pga") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/common/coloredtourgameimages/PGA2K21.png`;
  }  else if (gname === "mario") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/common/coloredtourgameimages/Mario_Kart.png`;
  }  else if (gname === "halo") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/common/coloredtourgameimages/Halo Logo.png`;
  }  else if (gname === "fallguys") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/common/coloredtourgameimages/Fall_Guys.png`;
  }    else if (gname === "codwz") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/common/coloredtourgameimages/Call_of_Duty_Warzone_Logo.png`;
  }    else if (gname === "amongus") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/common/coloredtourgameimages/Among_Us.png`;
  }   else {
    return ``;
  }
  }
// --------------------games page cards---------------------
export function gamespagecards(gname) {
  if (gname === "valorant") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/Valorant.jpg`;
  } else if (gname === "fortnite") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/Fortnite.jpg`;
  } else if (gname === "supersmash") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/Super Smash Bros.jpg`;
  } else if (gname === "madden") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/Madden 21.jpg`;
  } else if (gname === "nba20") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/NBA 2K22.jpg`;
  } else if (gname === "codmw") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/COD - Cold War.jpg`;
  } else if (gname === "codcw") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/COD - Cold War.jpg`;
  } else if (gname === "codwz") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/COD - Warzone.jpg`;
  }else if (gname === "fallguys") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/Fall Guys.jpg`;
  }  else if (gname === "amongus") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/Among Us.jpg`;
  } else if (gname === "apex") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/Apex Legends.jpg`;
  } else if (gname === "chess") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/Chess.jpg`;
  } else if (gname === "csgo") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/CS-GO.jpg`;
  } else if (gname === "deadbydaylight") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/Dead by Daylight.jpg`;
  } else if (gname === "f1") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/F1 2021.jpg`;
  } else if (gname === "legends") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/League of Legends.jpg`;
  } else if (gname === "minecraft") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/Minecraft.jpg`;
  } else if (gname === "mobilelegends") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/Mobile Legends.jpg`;
  } else if (gname === "mortal") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/Mortal Kombat.jpg`;
  } else if (gname === "paladins") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/Paladins.jpg`;
  } else if (gname === "pga") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/2K PGA 2K21.jpg`;
  } else if (gname === "pubg") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/2K PGA 2K21.jpg`;
  } else if (gname === "tetris") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/Tetris.jpg`;
  } else if (gname === "vallient") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/Valiant.jpg`;
  }else if (gname === "fifa21") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/FIFA 21.jpg`;
  }  else if (gname === "rocketleague") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/Rocket league.jpg`;
  }  else if(gname === "overwatch"){
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/Overwatch.jpg`;
  } else if(gname === "kqueen"){
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/Killer Queen.jpg`;
  } else if(gname === "mario"){
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/gamecards/Mario Kart.jpg`;
  }  else {
    return ``;
  }
  
  }
// --------------------games page cards---------------------

export function AllgamePlatforms() {
  return [{ 'key': 'ps4', 'value': 'PlayStation 5' },
  { 'key': 'xbox', 'value': 'XBOX' },
  { 'key': 'pc', 'value': 'PC' },
  { 'key': 'cross', 'value': 'Cross Platform' },
  { 'key': 'switch', 'value': 'SWITCH' }
];
}
export function AllgameIds() {
  return [{ 'key': 'psnid', 'value': 'PSN ID' },
          { 'key': 'xboxid', 'value': 'XBOX Gamertag' },
          { 'key': 'switchid', 'value': 'Switch Friend Code' },
          { 'key': 'riot_id', 'value': 'RIOT ID' },
          { 'key': 'epic_display_name', 'value': 'EPIC DSIPLAY NAME' },
          { 'key': 'stream_profile_name', 'value': 'STEAM PROFILE NAME' },
          { 'key': 'ea_id', 'value': 'EA ID' },
          { 'key': 'activision_id', 'value': 'Activision ID' },
          { 'key': 'battle_tag', 'value': 'BattleTag' },
          { 'key': 'liquid_id', 'value': 'LIQUID ID' }
        ];
}

export function gamePlatformName(gpname) {
if (gpname === "ps4") {
  return 'PlayStation 5';
} else if (gpname === "xbox") {
  return 'XBOX';
} else if (gpname === "pc") {
  return 'PC';
}  else if (gpname === "cross") {
  return 'Cross Platform';
} else if (gpname === "switch") {
  return 'SWITCH';
} else {
  return '';
}
}
//---------------games page images---------------------
export function gameImages(gname) {
  if (gname === "valorant") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Valorant-mobile.jpg`;
  } else if (gname === "fortnite") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Fortnite.jpg`;
  } else if (gname === "supersmash") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Super Smash Bros.jpg`;
  } else if (gname === "madden") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Madden 21.jpg`;
  } else if (gname === "nba20") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/NBA.jpg`;
  } else if (gname === "codmw") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Call of Duty Modern Warfare.jpg`;
  } else if (gname === "codcw") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Call of Duty Cold War.jpg`;
  } else if (gname === "codwz") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Call of Duty Warzone.jpg`;
  }else if (gname === "fallguys") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Fall Guys.jpg`;
  }  else if (gname === "amongus") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Among Us.jpg`;
  } else if (gname === "apex") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Apex Legends.jpg`;
  } else if (gname === "chess") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Chess.jpg`;
  } else if (gname === "csgo") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Counter-Strike- GO Paladins.jpg`;
  } else if (gname === "deadbydaylight") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Dead by Daylight-mobile.jpg`;
  } else if (gname === "f1") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/F1 2021.jpg`;
  } else if (gname === "legends") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/League of Legends.jpg`;
  } else if (gname === "minecraft") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Minecraft.jpg`;
  } else if (gname === "mobilelegends") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Mobile Legends.jpg`;
  } else if (gname === "mortal") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Mortal Kombat 11.jpg`;
  } else if (gname === "paladins") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Paladins-mobile.jpg`;
  } else if (gname === "pga") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/PGA Golf 2k21.jpg`;
  } else if (gname === "pubg") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/PUBG.jpg`;
  } else if (gname === "tetris") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Tetris.jpg`;
  } else if (gname === "vallient") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Valiant.jpg`;
  }else if (gname === "fifa21") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/FIFA 21.jpg`;
  }  else if (gname === "rocketleague") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Rocket League.jpg`;
  }  else if(gname === "overwatch"){
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/OVERWATCH.jpg`;
  } else {
    return ``;
  }
}


export function gamePlatformIcons(gpname) {
if (gpname === "ps4") {
  return 'https://res.cloudinary.com/dnv0dij0y/image/upload/v1574920962/platformicons/PSN_mwgnrg.png';
} else if (gpname === "xbox") {
  return 'https://res.cloudinary.com/dnv0dij0y/image/upload/v1574920962/platformicons/XBOX_pjbe6s.png';
} else if (gpname === "pc") {
  return 'https://res.cloudinary.com/dnv0dij0y/image/upload/v1574920962/platformicons/PC_baza0t.png';
}  else if (gpname === "cross") {
  return 'https://res.cloudinary.com/dg3sjwu2p/image/upload/v1591963574/New_Project_14_igftuu.png';
}  else if (gpname === "switch") {
  return 'https://res.cloudinary.com/dg3sjwu2p/image/upload/v1591963472/yyyyyyyyyyyy_mrvrjy.png';
} else {
  return '';
}
}
export function gamePlatformIconssvgs(gpname) {
  if (gpname === "ps4") {
    return <i className="fab fa-playstation"></i>;
  } else if (gpname === "xbox") {
    return <i className="fab fa-xbox"></i>;
  } else if (gpname === "pc") {
    return <i className="fas fa-laptop"></i>;
  }  else if (gpname === "cross") {
    return <i className="fas fa-random"></i>;
  }  else if (gpname === "switch") {
    return <i className="fas fa-gamepad"></i>;
  } else {
    return ;
  }
}

// --------------------------------------white icons---------------------------------
export function gamePlatformWhiteIcons(gpname) {
if (gpname === "ps4") {
  return 'https://res.cloudinary.com/dnv0dij0y/image/upload/v1574922604/platformicons/ps4_zgpkk1.png';
} else if (gpname === "xbox") {
  return 'https://res.cloudinary.com/dnv0dij0y/image/upload/v1574922605/platformicons/xbox_rl9bsu.png';
} else if (gpname === "pc") {
  return 'https://res.cloudinary.com/dnv0dij0y/image/upload/v1574922603/platformicons/pc_nyohs9.png';
}   else if (gpname === "cross") {
  return 'https://res.cloudinary.com/dg3sjwu2p/image/upload/v1586450100/image_3_cggys9.png';
}  else if (gpname === "switch") {
  return 'https://res.cloudinary.com/dg3sjwu2p/image/upload/v1591963475/yyyyyyyyyyyy_1_vzyc27.png';
}else {
  return '';
}
}
//----------------------team icons------------------------------------------
export function teamIcons(icon) {
if (icon === "1") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/assassin-with-two-swords-esport-gaming.png`;
} else if (icon === "2") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/clown-skull-esport-sport-mascot-l.png`;
} else if (icon === "3") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/cute-kitsune-with-sword-cartoon-character.png`;
} else if (icon === "4") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/gamers-mascot-logo.png`;
} else if (icon === "5") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/gorilla-head-logo-sport-club-team-animal-.png`;
} else if (icon === "6") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/hipster-man-mascot-logo.png`;
} else if (icon === "7") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/joker-head-clown-mascot-esports-mascot-logo.png`;
} else if (icon === "8") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/lion-esport-logo.png`;
} else if (icon === "9") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/vintage-strong-red-bull-head.png`;
} else if (icon === "10") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/mascot-logo-dragon.png`;
}  else if (icon === "11") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/modern-professional-knights-logo-mascot-g.png`;
} else if (icon === "12") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/scary-angry-black-panther-head.png`;
} else if (icon === "13") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/vintage-hipster-man-mascot-logo.png`;
} else if (icon === "14") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/Mascot1.png`;
} else if (icon === "15") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/Mascot2.png`;
} else if (icon === "16") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/Mascot3.png`;
} else if (icon === "17") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/Mascot4.png`;
} else if (icon === "18") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/Mascot5.png`;
} else if (icon === "19") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/Mascot6.png`;
} else if (icon === "20") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/Mascot7.png`;
} else if (icon === "21") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/Mascot8.png`;
} else if (icon === "22") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/Mascot9.png`;
} else if (icon === "23") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/Mascot10.png`;
} else if (icon === "24") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/Mascot11.png`;
} else if (icon === "25") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/Mascot12.png`;
} else if (icon === "26") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/Mascot13.png`;
} else if (icon === "27") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/Mascot14.png`;
} else if (icon === "28") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/teamicons/Mascot15.png`;
} else {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/events/Placeholder%20Game%20Card.jpg`;
}
}


export function playerType(type){
if(type === 'solo'){
  return 'Solo';
}else if(type === '22'){
  return '2 vs 2';
}else if(type === '33'){
  return '3 vs 3';
}else if(type === '44'){
  return '4 vs 4';
}else if(type === '55'){
  return '5 vs 5';
}else if(type === '11'){
  return 'Solo';
}
}
export function playerTypePlayers(type){
if(type === 'solo'){
  return 'Solo';
}else if(type === '22'){
  return '2';
}else if(type === '33'){
  return '3';
}else if(type === '44'){
  return '4';
}else if(type === '55'){
  return '5';
}else if(type === '11'){
  return 'Solo';
}
}
export function typeofplayerName(type){
if(type === 'solo'){
  return 'Solo';
}else if(type === '22'){
  return '2 vs 2';
}else if(type === '33'){
  return '3 vs 3';
}else if(type === '44'){
  return '4 vs 4';
}else if(type === '55'){
  return '5 vs 5';
}else if(type === '66'){
  return '6 vs 6';
}else if(type === '11'){
  return 'Solo';
}else{
  return'';
}
}
export function gametourBanners(gname) {
  if (gname === "valorant") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/Valorant.jpg`;
  } else if (gname === "fortnite") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/Fortnite.jpg`;
  } else if (gname === "supersmash") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/Super Smash Bros.jpg`;
  } else if (gname === "madden") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/Madden 21.jpg`;
  } else if (gname === "nba20") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/NBA 2K22.jpg`;
  } else if (gname === "codmw") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/Call of Duty Modern Warfare.jpg`;
  } else if (gname === "codcw") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/Call of Duty Cold War.jpg`;
  } else if (gname === "codwz") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/Call of Duty Warzone.jpg`;
  }else if (gname === "fallguys") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/Fall Guys.jpg`;
  }  else if (gname === "amongus") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/Among Us.jpg`;
  } else if (gname === "apex") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/Apex Legends.jpg`;
  } else if (gname === "chess") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/Chess.jpg`;
  } else if (gname === "csgo") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/Counter-Strike- GO Paladins.jpg`;
  } else if (gname === "deadbydaylight") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/Dead by Daylight-desktop.jpg`;
  } else if (gname === "f1") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/F1 2021.jpg`;
  } else if (gname === "legends") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/League of Legends.jpg`;
  } else if (gname === "minecraft") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/Minecraft.jpg`;
  } else if (gname === "mobilelegends") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/Mobile Legends.jpg`;
  } else if (gname === "mortal") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/Mortal Kombat 11.jpg`;
  } else if (gname === "paladins") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/Paladins.jpg`;
  } else if (gname === "pga") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/PGA Golf 2k21.jpg`;
  } else if (gname === "pubg") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/PUBG.jpg`;
  } else if (gname === "tetris") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/Tetris.jpg`;
  } else if (gname === "vallient") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/Valiant.jpg`;
  }else if (gname === "fifa21") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/FIFA 21.jpg`;
  }  else if (gname === "rocketleague") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/Rocket League.jpg`;
  }  else if(gname === "overwatch"){
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/desktop/OVERWATCH.jpg`;
  } else {
    return ``;
  }
}
export function gametourPhoneBanners(gname) {
  if (gname === "valorant") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Valorant-mobile.jpg`;
  } else if (gname === "fortnite") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Fortnite.jpg`;
  } else if (gname === "supersmash") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Super Smash Bros.jpg`;
  } else if (gname === "madden") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Madden 21.jpg`;
  } else if (gname === "nba20") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/NBA.jpg`;
  } else if (gname === "codmw") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Call of Duty Modern Warfare.jpg`;
  } else if (gname === "codcw") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Call of Duty Cold War.jpg`;
  } else if (gname === "codwz") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Call of Duty Warzone.jpg`;
  }else if (gname === "fallguys") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Fall Guys.jpg`;
  }  else if (gname === "amongus") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Among Us.jpg`;
  } else if (gname === "apex") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Apex Legends.jpg`;
  } else if (gname === "chess") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Chess.jpg`;
  } else if (gname === "csgo") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Counter-Strike- GO Paladins.jpg`;
  } else if (gname === "deadbydaylight") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Dead by Daylight-mobile.jpg`;
  } else if (gname === "f1") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/F1 2021.jpg`;
  } else if (gname === "legends") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/League of Legends.jpg`;
  } else if (gname === "minecraft") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Minecraft.jpg`;
  } else if (gname === "mobilelegends") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Mobile Legends.jpg`;
  } else if (gname === "mortal") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Mortal Kombat 11.jpg`;
  } else if (gname === "paladins") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Paladins-mobile.jpg`;
  } else if (gname === "pga") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/PGA Golf 2k21.jpg`;
  } else if (gname === "pubg") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/PUBG.jpg`;
  } else if (gname === "tetris") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Tetris.jpg`;
  } else if (gname === "vallient") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Valiant.jpg`;
  }else if (gname === "fifa21") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/FIFA 21.jpg`;
  }  else if (gname === "rocketleague") {
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/Rocket League.jpg`;
  }  else if(gname === "overwatch"){
    return `${process.env.REACT_APP_S3BUCKET_COMMON}/games/mobile/OVERWATCH.jpg`;
  } else {
    return ``;
  }
}

export function dateformat(date) {
  return new Date(date).toLocaleDateString();
}

export function dateformatMonthDate(date) {

//   var mydate = date;

//   mydate.UTC(year, month, day, hours, minutes, seconds, millisec)
}

export function timeFormat(time) { 
// Check correct time format and split into components
time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

if (time.length > 1) { // If time format correct
  time = time.slice (1);  // Remove full string match value
  time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
  time[0] = +time[0] % 12 || 12; // Adjust hours
}
return time.join (''); // return adjusted time or original string
} 


export function userData(param) {
var currid = parseJwt(localStorage.getItem('token'));
var curid = currid.sub; 
    return axios.get(`${process.env.REACT_APP_APIURL}/user/get-user/${curid}`, { headers: { "Content-Type": "application/json", 'X-Auth-Token': `${localStorage.getItem('token')}` } })
    .then(response => {
      let result = '';
      // var gg = response.data.data.nickname;
      // var ggg = response.data.data.uniquecode;
      // const tasks = Object.value(response.data.data);
      const tasks = response.data.data;
      // tasks.forEach(task =>
        Object.entries(tasks).forEach(([key, value]) => {
          if(param == key){
            result = value;
          }
        })
          // if(param === key){

          // }
          // formattedTasks.push({ name: key, data: value })
        // )
      // );
        // const { [param]:result } = response.data.data;

        
        return "result";
        
     })
    .catch((error) => {
     });
// return "dfgdf";
}

export async function checkapidata(param){ 
  var platform = `${process.env.REACT_APP_PLATFORM_SUFFIX}`;
  let request;
  request = {
      method: 'GET',
      url: `${process.env.REACT_APP_APIURL}/get-client-details/${platform}`,
      headers: { 'Content-Type': 'application/json' },
  };
  
  return await axios(request).then((response) => {
    const responseee = response.data.data;
    // if (param == "primarylogo") {
    //   return responseee.logo_two;
    // } else if (param === "secondarylogo") {
    //   return responseee.logo_two;
    // }else if (param === "loader_url") {
    //   return responseee.loader_url;
    // } else if (param === "banner_loader") {
    //   return responseee.banner_loader;
    // } else {
      return responseee;
    // }
  })
  // return 'sdfgr'; 
}
// export const checkapidata = async (id) => {
//   var platform = `${process.env.REACT_APP_PLATFORM_SUFFIX}`;
//   try {
//     let request;
//     request = {
//           method: 'GET',
//           url: `${process.env.REACT_APP_APIURL}/get-client-details/${platform}`,
//           headers: { 'Content-Type': 'application/json' },
//       };
//     const response = await axios(request);

//     // const data = await response.json();

//     // if (data.error) {
//       // throw new Error('Error!');
//     // }

//     return response;
//   } catch (err) {
//   }
// };
export function userDynamicData(param) {
  
      var jj = axios.get(`${process.env.REACT_APP_APIURL}/get-client-details/${process.env.REACT_APP_PLATFORM_SUFFIX}`, { headers: { "Content-Type": "application/json" } })
      .then(response => {
        let result = '';
         const tasks = response.data.data.logo_one;
         return tasks;
       })
      .catch((error) => {
       });
  }
export function eventImages(gname) {
if (gname === "madden") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/eventthumb/Madden 21.png`;
} else if (gname === "fortnite") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/eventthumb/Fortnite 1.png`;
}else if (gname === "supersmash") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/eventthumb/Super Smash Bros.png`;
} else if (gname === "rocketleague") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/eventthumb/Rocket League.png`;
}else if(gname === "valorant"){
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/eventthumb/Valorant 1.png`;
}else if(gname === "nba20"){
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/eventthumb/NBA 2K21 1.png`;
}    else if (gname === "codmw") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/eventthumb/COD - MW.png`;
} else if (gname === "codcw") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/eventthumb/COD - Cold War.png`;
}  else if (gname === "codwz") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/eventthumb/COD - Warzone.png`;
}else if (gname === "fallguys") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/eventthumb/Fall Guys.png`;
} else if (gname === "fifa21") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/eventthumb/FIFA 21.png`;
}  else if (gname === "csgo") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/eventthumb/CS-GO.png`;
} else if (gname === "halo") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/eventthumb/HALO.png`;
} else if (gname === "legends") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/eventthumb/League of Legends.png`;
} else if (gname === "mario") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/eventthumb/Mariokart.png`;
} else if (gname === "nascar") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/eventthumb/Mascar Heat 5.png`;
}  else if (gname === "mortal") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/eventthumb/Mortal Kombat 11.png`;
}  else if (gname === "overwatch") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/eventthumb/Overwatch.png`;
}  else if (gname === "pga") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/eventthumb/PGA 2K21.png`;
}  else if (gname === "pubg") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/eventthumb/PUBG.png`;
}  else if (gname === "rainbow") {
  return `${process.env.REACT_APP_S3BUCKET_COMMON}/eventthumb/Rainbow Six.png`;
}  else {
  return ``;
}
}



export function gamezendeskurls(gname) {
  if (gname === "madden") {
    return 'https://mavsgaminghub.zendesk.com/hc/en-us/articles/1500012021021-Lobby-Setup-for-Madden-21';
  } else if (gname === "fortnite") {
    return 'https://mavsgaminghub.zendesk.com/hc/en-us/articles/1500011587362-Private-Lobby-Setup-for-Fortnite';
  }else if (gname === "supersmash") {
    return 'https://mavsgaminghub.zendesk.com/hc/en-us/articles/1500012040441-Private-Lobby-Setup-for-Smash-Bros-Ultimate-Arena';
  } else if (gname === "rocketleague") {
    return 'https://mavsgaminghub.zendesk.com/hc/en-us/articles/1500012243942-Private-Lobby-Setup-for-Rocket-League';
  }else if(gname === "valorant"){
    return 'https://mavsgaminghub.zendesk.com/hc/en-us/articles/1500011977922-Private-Lobby-Setup-for-Valorant';
  }else if(gname === "nba20"){
    return 'https://mavsgaminghub.zendesk.com/hc/en-us/articles/1500012036981-Private-Lobby-Setup-for-NBA-2K21';
  }    else if (gname === "codmw") {
    return 'https://mavsgaminghub.zendesk.com/hc/en-us/articles/1500011975122-Private-Lobby-Setup-for-COD';
  } else if (gname === "codcw") {
    return 'https://mavsgaminghub.zendesk.com/hc/en-us/articles/1500011975122-Private-Lobby-Setup-for-COD';
  }  else if (gname === "codwz") {
    return 'https://mavsgaminghub.zendesk.com/hc/en-us/articles/1500011975122-Private-Lobby-Setup-for-COD';
  }else if (gname === "fallguys") {
    return 'https://mavsgaminghub.zendesk.com/hc/en-us/categories/1500001415641-How-To-Guides';
  } else if (gname === "fifa21") {
    return 'https://mavsgaminghub.zendesk.com/hc/en-us/articles/4401991539091-Private-Lobby-Setup-for-FIFA-21';
  }  else if (gname === "csgo") {
    return 'https://mavsgaminghub.zendesk.com/hc/en-us/categories/1500001415641-How-To-Guides';
  } else if (gname === "halo") {
    return 'https://mavsgaminghub.zendesk.com/hc/en-us/categories/1500001415641-How-To-Guides';
  } else if (gname === "legends") {
    return 'https://mavsgaminghub.zendesk.com/hc/en-us/articles/1500012032961-Private-Lobby-Setup-for-League-of-Legends';
  } else if (gname === "mario") {
    return 'https://mavsgaminghub.zendesk.com/hc/en-us/categories/1500001415641-How-To-Guides';
  } else if (gname === "nascar") {
    return 'https://mavsgaminghub.zendesk.com/hc/en-us/categories/1500001415641-How-To-Guides';
  }  else if (gname === "mortal") {
    return 'https://mavsgaminghub.zendesk.com/hc/en-us/categories/1500001415641-How-To-Guides';
  }  else if (gname === "overwatch") {
    return 'https://mavsgaminghub.zendesk.com/hc/en-us/articles/1500011972462-Private-Lobby-Setup-For-Overwatch';
  }  else if (gname === "pga") {
    return 'https://mavsgaminghub.zendesk.com/hc/en-us/categories/1500001415641-How-To-Guides';
  }  else if (gname === "pubg") {
    return 'https://mavsgaminghub.zendesk.com/hc/en-us/categories/1500001415641-How-To-Guides';
  }  else if (gname === "rainbow") {
    return 'https://mavsgaminghub.zendesk.com/hc/en-us/categories/1500001415641-How-To-Guides';
  }  else {
    return '';
  }
  }

export function eventbannerImages(gname) {
if (gname === "madden") {
  return 'https://res.cloudinary.com/dg3sjwu2p/image/upload/v1597998540/SWAC/events/banners/MADDEN21_Calendar_Banner_kfijnd.png';
} else if (gname === "fortnite") {
  return 'https://res.cloudinary.com/dg3sjwu2p/image/upload/v1597998540/SWAC/events/banners/Fortnite_Calendar_Banner_qhen6w.png';
}else if (gname === "supersmash") {
  return 'https://res.cloudinary.com/dg3sjwu2p/image/upload/v1597998541/SWAC/events/banners/SMASH_Bros_Calendar_Banner_kb7nj5.png';
} else if (gname === "rocketleague") {
  return 'https://res.cloudinary.com/dg3sjwu2p/image/upload/v1597998543/SWAC/events/banners/Rocket_League_Calendar_Banner_mbazwf.png';
}else if(gname === "valorant"){
  return 'https://res.cloudinary.com/dg3sjwu2p/image/upload/v1597998543/SWAC/events/banners/Valorant_Calendar_Banner_ngctww.png';
}else if(gname === "nba20"){
  return 'https://res.cloudinary.com/dg3sjwu2p/image/upload/v1597998541/SWAC/events/banners/NBA2K_21_Calendar_Banner_yjkwaf.jpg';
}  else {
  return '';
}
}
export function eventImagesName(gname) {
if (gname === "madden") {
  return 'Madden21.png';
} else if (gname === "fortnite") {
  return 'Fortnite.png';
}else if (gname === "supersmash") {
  return 'super-smash-bros.png';
} else if (gname === "rocketleague") {
  return 'rocket-league.png';
}else if(gname === "valorant"){
  return 'Valorant.png';
}else if(gname === "nba20"){
  return 'NBA2K21.png';
} else if (gname === "codmw") {
  return 'COD-MW.png';
} else if (gname === "codcw") {
  return 'COD-ColdWar.png';
}  else if (gname === "codwz") {
  return 'COD-Warzone.png';
}else if (gname === "mortal") {
  return 'mortalkombat.png';
}  else if (gname === "fifa21") {
  return 'FIFA21.png';
} else if (gname === "csgo") {
  return 'CS-GO.png';
} else if (gname === "fallguys") {
  return 'FallGuys.png';
} else if (gname === "halo") {
  return 'HALO.png';
} else if (gname === "legends") {
  return 'LeagueofLegends.png';
} else if (gname === "mario") {
  return 'Mariokart.png';
} else if (gname === "nascar") {
  return 'MascarHeat5.png';
} else if (gname === "overwatch") {
  return 'Overwatch.png';
} else if (gname === "pga") {
  return 'PGA2K21.png';
} else if (gname === "pubg") {
  return 'PUBG.png';
} else if (gname === "rainbow") {
  return 'RainbowSix.png';
} else {
  return '';
}
}
export function swalpopup(message, alert) {
  Swal.fire({
    toast: true,
    icon: alert,
    title: message,
    animation: false,
    position: 'top-right',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
}
export async function getclientfirebasedetails(){ 
  var platform = window.$REACT_APP_PLATFORM_SUFFIX;
  if(localStorage.getItem('token')){
      let request;
      request = {
          method: 'GET',
          url: `${process.env.REACT_APP_APIURL}/user/get-config-firebase-details/firebase`,
          // headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
          headers: { 'Content-Type': 'application/json','X-Auth-Token': `${localStorage.getItem('token')}` },
      };
      
      return await axios(request).then((response) => {
        // const responseee = response.data.data;
        if (response) {
          if (response.data) {
            if (response.data.ResponseCode === '0') {
              const platformdata_client = "";	
              // window.$FIREBASE_APIKEY = "";
              // window.$FIREBASE_AUTHDOMAIN = "";
              // window.$FIREBASE_DBURL = "";
              // window.$FIREBASE_PROJECTID = "";
              // window.$FIREBASE_STORAGEBUCKET = "";
              // window.$FIREBASE_MSGSENDERID = "";
              // window.$FIREBASE_APPID = "";
              // window.$FIREBASE_MEASUREMENTID = "";
              window.$FIREBASE_CREDENTIALS_EXIST = 'false';

              localStorage.setItem('firebase_credentials_apiKey', "");
              localStorage.setItem('firebase_credentials_authDomain', "");
              localStorage.setItem('firebase_credentials_databaseURL', "");
              localStorage.setItem('firebase_credentials_projectId', "");
              localStorage.setItem('firebase_credentials_storageBucket', "");
              localStorage.setItem('firebase_credentials_messagingSenderId', "");
              localStorage.setItem('firebase_credentials_appId', "");
              localStorage.setItem('firebase_credentials_measurementId', "");

              localStorage.setItem('firebase_credentials', false);
              return platformdata_client;
            } else {
              const platformdata_client = parseJwt(response.data.data);	
              // window.$FIREBASE_APIKEY = platformdata_client.client.apiKey;
              // window.$FIREBASE_AUTHDOMAIN = platformdata_client.client.authDomain;
              // window.$FIREBASE_DBURL = platformdata_client.client.databaseURL;
              // window.$FIREBASE_PROJECTID = platformdata_client.client.projectId;
              // window.$FIREBASE_STORAGEBUCKET = platformdata_client.client.storageBucket;
              // window.$FIREBASE_MSGSENDERID = platformdata_client.client.messagingSenderId;
              // window.$FIREBASE_APPID = platformdata_client.client.appId;
              // window.$FIREBASE_MEASUREMENTID = platformdata_client.client.measurementId; 

              localStorage.setItem('firebase_credentials_apiKey', platformdata_client.client.apiKey);
              localStorage.setItem('firebase_credentials_authDomain', platformdata_client.client.authDomain);
              localStorage.setItem('firebase_credentials_databaseURL', platformdata_client.client.databaseURL);
              localStorage.setItem('firebase_credentials_projectId', platformdata_client.client.projectId);
              localStorage.setItem('firebase_credentials_storageBucket', platformdata_client.client.storageBucket);
              localStorage.setItem('firebase_credentials_messagingSenderId', platformdata_client.client.messagingSenderId);
              localStorage.setItem('firebase_credentials_appId', platformdata_client.client.appId);
              localStorage.setItem('firebase_credentials_measurementId', platformdata_client.client.measurementId);

              window.$FIREBASE_CREDENTIALS_EXIST = 'true';

              localStorage.setItem('firebase_credentials', true);
              return platformdata_client;
            }
          }
        }
        // }
      })
  }
      
}
