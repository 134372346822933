import * as types from "../types/types";

const initalState = {
    loading: true,
    joinedloading:true,
    teamleaderloading:true,
    data: [],
    dataa:[],
    messageData:[],
    allusersteams:[],
    specificteams:[],
    allspecificteams:[],
    daataa:[],
    joineddata:[],
    leaveteam:[],
    allleaderboardteams:[],
    alldeletedteams:[],
    allteamavatar: [],
}

const teamReducer = (state = initalState, action) => {

    switch (action.type) {
        case types.FETCH_DATA_BEGIN:
            return {
                ...state,
                loading: true,
                teamleaderloading:true
            }
        case types.FETCH_JOINED_DATA_BEGIN:
            return {
                ...state,
                joinedloading: true
            }
        case types.FETCH_JOINED_TEAM_SUCCESS:
            return {
                ...state,
                joinedloading: false,
                joineddata: action.payload
            }
        case types.FETCH_TEAM_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
            case types.FETCH_ALL_USERS_TEAM_SUCCESS:
            return {
                ...state,
                loading: false,
                allusersteams: action.payload
            }
            case types.FETCH_SINGLE_TEAM_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    dataa: action.payload
                }
            case types.FETCH_SINGLE_TEAM:
                return {
                ...state,
                loading: false,
                daataa: action.payload
                }
            case types.FETCH_LEAVE_SUCCESS:
                return {
                ...state,
                loading: false,
                leaveteam: action.payload
                }
            case types.FETCH_SPECIFIC_TEAM:
                return {
                ...state,
                loading: false,
                specificteams: action.payload
                }
                case types.FETCH_ALL_SPECIFIC_TEAM:
                return {
                ...state,
                loading: false,
                allspecificteams: action.payload
                }
                case types.FETCH_ALL_TEAM_LEADER:
                    return {
                    ...state,
                    teamleaderloading: false,
                    allleaderboardteams: action.payload
                    }
                case types.FETCH_ALL_DELETED_TEAM:
                    return {
                    ...state,
                    teamleaderloading: false,
                    alldeletedteams: action.payload
                    }
                case types.FETCH_ALL_TEAM_AVATARS:
                    return {
                    ...state,
                    teamleaderloading: false,
                    allteamavatar: action.payload
                }
                    
        default:
            return state
    }
}

export default teamReducer;