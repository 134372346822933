import * as types from "../types/types";

const initalState = {
    loading: true,
    data: [],
   
    usermenuloding:[],
    allmenus:[],
    allAdminMenus:[],
    customadminsubmenudata:[],
    pagedata: [],
    pagedataloading: [],
    customsidebarmenudata:[],
    alldynamiccssversions:[],
    allusermenus:[],
    footersociallinks:[],
    secureclientdataloading:true, 
    secureclientdata: [],
    allfeeds: [],
    singlefeedcomments:[],
    allhelpcentermenus:[],
    singlehelpcentermenus: '',
    customadminnewmenuloding: true,
    customadminnewmenudata: []

}

const MenuReducer = (state = initalState, action) => {
    // console.log(action.payload);
    switch (action.type) {
        
        case types.FETCH_MENU_BEGIN:
            return {
                ...state,
                custommenuloding: true,
                //: action.payload
            }
        case types.FETCH_MENU_SUCCESS:
            return {
                ...state,   
                custommenuloding: false,
                custommenudata: action.payload
            }
        case types.FETCH_DYNAMIC_SIDEBAR_MENU_SUCCESS:
            return {
                ...state,
                custommenuloding: false,
                customsidebarmenudata: action.payload
            }
        case types.FETCH_ADMIN_MENU_BEGIN:
            return {
                ...state,
                customadminmenuloding: true,
            }
        case types.FETCH_ADMIN_MENU_SUCCESS:
            return {
                ...state,
                customadminmenuloding: false,
                customadminmenudata: action.payload
            }
            case types.FETCH_ADMIN_NEW_MENU_BEGIN:
                return {
                    ...state,
                    customadminnewmenuloding: true,
                }
            case types.FETCH_ADMIN_NEW_MENU_SUCCESS:
                return {
                    ...state,
                    customadminnewmenuloding: false,
                    customadminnewmenudata: action.payload
                }
        case types.FETCH_ALL_SUBMENU_SUCCESS:
            return{
                ...state,
                customadminmenuloding: false,
                customadminsubmenudata: action.payload
            }
        case types.FETCH_ALL_PERMISSIONS_SUCCESS:
            return{
                ...state,
                customadminmenuloding: false,
                allpermissiondata: action.payload
            }
     
        case types.FETCH_HELP_CENTER_MENU_SUCCESS:
            return{
                ...state,
                loading: false,
                allhelpcentermenus: action.payload
            }
        case types.FETCH_SINGLE_HELP_CENTER_MENU_SUCCESS:
            return {
                ...state,
                loading: false,
                singlehelpcentermenus: action.payload
            }
             
            // createsecuresignedurldataloading:true,
            // createsecuresignedurldata:[],
        default:
            return state
    }
}

export default MenuReducer;