  
 import * as types from "../types/types";

 const initalState = {
     loading: true,
     loadingtwo:true,
     loadingg:true,
     singlebracketdata_loading:true,
     loadingtourticket:true,
     alltourtickets_loading:true,
     singlebracketdata: [],
     alltourtickets:[],
     singletourticket:[],
     singlebracketdatatwo:[],
     dataa:[],
     roundrobinbrac:[],
     bracloading: true,
 }
 
 const bracketsReducer = (state = initalState, action) => {
     switch (action.type) {
         case types.FETCH_BRACKETS_BEGIN:
             return {
                 ...state,
                 loading: true
             }
             case types.FETCH_START_POPS:
                return {
                    ...state,
                    loadingg: true
                }
        case types.FETCH_POPS_FINISH_SUCCESS:
             return {
                 ...state,
                 loadingg: false,
                 dataa: action.payload
             }
         case types.FETCH_BRACKETS_SUCCESS:
             return {
                 ...state,
                 singlebracketdata_loading: false,
                 singlebracketdata: action.payload
             }
             case types.FETCH_TOURTICKSETS_SUCCESS:
                return {
                    ...state,
                    alltourtickets_loading: false,
                    alltourtickets: action.payload
                }
                case types.FETCH_SINGLE_TOURTICKSETS_SUCCESS:
                return {
                    ...state,
                    loadingtourticket: false,
                    singletourticket: action.payload
                }
                case types.FETCH_BRACKETS_TWO_SUCCESS:
                return {
                    ...state,
                    loadingtwo: false,
                    singlebracketdatatwo: action.payload
                }
                case types.FETCH_ROUND_ROBIN_BRACKET_SUCCESS:
                    return {
                        ...state,
                        bracloading: false,
                        roundrobinbrac: action.payload
                    }
                case types.FETCH_START_ROUND_ROBIN:
                    return {
                        ...state,
                        bracloading: true
                    }
                
         default:
             return state
     }
 }
 
 export default bracketsReducer;