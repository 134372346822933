import * as types from "../types/types";

const initalState = {
    loading:true,
    signuptrack:[],
    signuploading:true,
    challengecreationdata:[]
}

const TrackReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.FETCH_TRACK_BEGIN:
            return {
                ...state,
                loading: true,
            }
        case types.SIGNUP_TRACK_SUCCESS:
            return {
                ...state,
                signuptrack: action.payload,
                loading: false
            }
            case types.CHALLENGE_CREATION_TRACK_SUCCESS:
                return {
                    ...state,
                    challengecreationdata: action.payload,
                    loading: false
                }
        default:
            return state
    }
}

export default TrackReducer;