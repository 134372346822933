import React, { Component } from 'react';
import { connect } from 'react-redux';
import SignupHeader from './xrgames/SignupHeader';
import AdminFooter from './AdminFooter';
import AdminSideNavBar from './AdminSideNavBar';
import $ from 'jquery';
import { withRouter } from 'react-router';
import moment from 'moment';
class AdminInnerLayout extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
            if(window.location.pathname !== "/customize-homepage"){
                $('head').find('link#css1').remove();
                $('head').find('link#css2').remove();
                $('head').find('link#css21').remove();
                $('head').find('link#css3').remove();
                $('head').find('link#css4').remove();
                $('head').find('link#css5').remove();
                $('head').find('link#css6').remove();
            }else{

                var head = document.head;
                var link = document.createElement("link");
                link.id = "css1";
                link.type = "text/css";
                link.rel = "stylesheet";
                link.href = "https://near.activelink.network/assets/css/customstylee.css?ver="+ moment().format('YYYYMM-DDHHmmss');
                head.appendChild(link);

                var link = document.createElement("link");
                link.id = "css2";
                link.type = "text/css";
                link.rel = "stylesheet";
                link.href = "https://near.activelink.network/assets/css/dark-theme2.css?ver="+ moment().format('YYYYMM-DDHHmmss');
                head.appendChild(link);

                var link = document.createElement("link");
                link.id = "css21";
                link.type = "text/css";
                link.rel = "stylesheet";
                link.href = "https://near.activelink.network/assets/css/dark-theme3.css?ver="+ moment().format('YYYYMM-DDHHmmss');
                head.appendChild(link);

                var link = document.createElement("link");
                link.id = "css3";
                link.type = "text/css";
                link.rel = "stylesheet";
                link.href = "https://near.activelink.network/assets/css/dark-theme.css?ver="+ moment().format('YYYYMM-DDHHmmss');
                head.appendChild(link);

                var link = document.createElement("link");
                link.id = "css4";
                link.type = "text/css";
                link.rel = "stylesheet";
                link.href = "https://near.activelink.network/assets/css/style1480.css?ver="+ moment().format('YYYYMM-DDHHmmss');
                head.appendChild(link);
                
                var link = document.createElement("link");
                link.id = "css5";
                link.type = "text/css";
                link.rel = "stylesheet";
                link.href = "https://near.activelink.network/assets/css/style480.css?ver="+ moment().format('YYYYMM-DDHHmmss');
                head.appendChild(link);

                var link = document.createElement("link");
                link.id = "css6";
                link.type = "text/css";
                link.rel = "stylesheet";
                link.href = "https://near.activelink.network/assets/css/style767.css?ver="+ moment().format('YYYYMM-DDHHmmss');
                head.appendChild(link);
            }

    }
    componentWillReceiveProps(nextProps) {
        if(window.location.pathname !== "/customize-homepage"){
            $('head').find('link#css1').remove();
            $('head').find('link#css2').remove();
            $('head').find('link#css21').remove();
            $('head').find('link#css3').remove();
            $('head').find('link#css4').remove();
            $('head').find('link#css5').remove();
            $('head').find('link#css6').remove();
        }else{

            var head = document.head;
            var link = document.createElement("link");
            link.id = "css1";
            link.type = "text/css";
            link.rel = "stylesheet";
            link.href = "https://near.activelink.network/assets/css/customstylee.css?ver="+ moment().format('YYYYMM-DDHHmmss');
            head.appendChild(link);

            var link = document.createElement("link");
            link.id = "css2";
            link.type = "text/css";
            link.rel = "stylesheet";
            link.href = "https://near.activelink.network/assets/css/dark-theme2.css?ver="+ moment().format('YYYYMM-DDHHmmss');
            head.appendChild(link);


            var link = document.createElement("link");
            link.id = "css21";
            link.type = "text/css";
            link.rel = "stylesheet";
            link.href = "https://near.activelink.network/assets/css/dark-theme3.css?ver="+ moment().format('YYYYMM-DDHHmmss');
            head.appendChild(link);

            var link = document.createElement("link");
            link.id = "css3";
            link.type = "text/css";
            link.rel = "stylesheet";
            link.href = "https://near.activelink.network/assets/css/dark-theme.css?ver="+ moment().format('YYYYMM-DDHHmmss');
            head.appendChild(link);

            var link = document.createElement("link");
            link.id = "css4";
            link.type = "text/css";
            link.rel = "stylesheet";
            link.href = "https://near.activelink.network/assets/css/style1480.css?ver="+ moment().format('YYYYMM-DDHHmmss');
            head.appendChild(link);
            
            var link = document.createElement("link");
            link.id = "css5";
            link.type = "text/css";
            link.rel = "stylesheet";
            link.href = "https://near.activelink.network/assets/css/style480.css?ver="+ moment().format('YYYYMM-DDHHmmss');
            head.appendChild(link);

            var link = document.createElement("link");
            link.id = "css6";
            link.type = "text/css";
            link.rel = "stylesheet";
            link.href = "https://near.activelink.network/assets/css/style767.css?ver="+ moment().format('YYYYMM-DDHHmmss');
            head.appendChild(link);
        }
    }
    render() { 
        return (
            
                <div id="wrapper"  className="adminlayout">
                <SignupHeader/>
                    <AdminSideNavBar history={this.props.history}/>    
                    {
                        window.location.pathname === "/customize-homepage" || window.location.pathname === "/add-custom-menu" || window.location.pathname === "/navigation-boxes" || window.location.pathname === "/lobby-banner-setup" ? 
                        <div className="adminright customize-menu"> 
                            <div className="px-0 float-start w-100">
                                <div className="common-page-layout mt-0">
                                    {this.props.children}
                                </div>
                            </div>
                        </div>
                        :
                        <div className="adminright"> 
                            <div className="px-lg-5 px-md-5 px-sm-3 px-3">
                                <div className="common-page-layout mt-4">
                                    {this.props.children}
                                </div>
                            </div>
                        </div>
                    }               
                    
                </div>

            
        );
    }
    }

    export default withRouter(AdminInnerLayout); 
// export default AdminInnerLayout;