import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { withRouter } from 'react-router'
import { FormGroup, FormControl } from 'react-bootstrap';
import parseJwt, { apiBaseUrl } from './../../store/helpers/common';
import axios from 'axios';
import toastr from "reactjs-toastr";
import $ from 'jquery';
import swal from 'sweetalert';
import { GoogleLogin } from 'react-google-login';


class XRSwitch extends Component {
    constructor(props) {
        super(props);
        this.loginValidator = new SimpleReactValidator();
        this.state = {
            light_theme:true,
        }
    }
    componentDidMount() {
        if(localStorage.getItem('sc_theme_yf')){
            this.setState({
                light_theme:localStorage.getItem('sc_theme_yf') == 'true' ? true : false
            })
        }
    }
    handleCheckbox(e) {
        this.setState({
        // [e.target.name]:e.target.checked
        [e.target.name]:!this.state.light_theme
        })
        localStorage.setItem('sc_theme_yf',!this.state.light_theme)
        window.$('.main-content-sec').toggleClass('sc-yf');
    }
    render() {
        const {light_theme} = this.state;
        
        return (
            <>
                
                <div className="toggle">
                    <div className="xr-switch-holder ani">
                        <label className={this.state.light_theme?'active':''}>Toggle
                        {
                                this.state.light_theme === true
                                ?
                                    <input type="checkbox" className="aabb xr-swtich"  name="light_theme" value={this.state.light_theme} onClick={(e) => this.handleCheckbox(e)} defaultChecked={true}/>
                                :
                                    <input type="checkbox" className="bb xr-swtich" name="light_theme" value={this.state.light_theme} onClick={(e) => this.handleCheckbox(e)} defaultChecked={false}/>
                            }
                        </label>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(XRSwitch);