  
 import * as types from "../types/types";

 const initalState = {
     loading: true,
     allnews: [],
     singlenews_loading: true,
     singlenews: [],
     allplaylist_loading: true,
     allplaylist: [],
 }
 
 const MediaReducer = (state = initalState, action) => {
     switch (action.type) {
         case types.FETCH_NEWS_BEGIN:
             return {
                 ...state,
                 loading: true
             }
         case types.FETCH_NEWS_SUCCESS:
             return {
                 ...state,
                 loading: false,
                 allnews: action.payload
             }

             case types.FETCH_SINGLE_NEWS_BEGIN:
                return {
                    ...state,
                    singlenews_loading: true
                }
            case types.FETCH_SINGLE_NEWS_SUCCESS:
                return {
                    ...state,
                    singlenews_loading: false,
                    singlenews: action.payload
                }
         case types.FETCH_ALL_PLAYLIST_SUCCESS:
            return{
                ...state,
                allplaylist_loading: false,
                allplaylist: action.payload,
            }

         default:
             return state
     }
 }
 export default MediaReducer;