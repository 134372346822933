import { apiBaseUrl} from '../helpers/common';
import * as actionTypes from '../types/types';
import {handleResponse} from '../helpers/userServices';


export const fetchStart = () => {
    return {
        type: actionTypes.FETCH_BANNERS_BEGIN
    }
}

export const fetchbannerSuccess = (data) => {
    return {
        type: actionTypes.FETCH_BANNERS_SUCCESS,
        payload: data.data
    }
}
export const fetchsinglebannerSuccess = (data) => {
    return {
        type: actionTypes.FETCH_SINGLE_BANNERS_SUCCESS,
        payload: data.data
    }
}
export function getAllbanners(id=`${process.env.REACT_APP_PLATFORM_SUFFIX}`) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-banners/${id}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchbannerSuccess(data))
        });
    }
}
export function getSinglebanner(id,idd=`${process.env.REACT_APP_PLATFORM_SUFFIX}`) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-banner/${id}/${idd}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchsinglebannerSuccess(data))
        });
    }
}


// ------------------------------------homepage banners------------------------------------


export const fetchDeskbannerSuccess = (data) => {
    return {
        type: actionTypes.FETCH_DESK_BANNERS_SUCCESS,
        payload: data.data
    }
}
export const fetchMobbannerSuccess = (data) => {
    return {
        type: actionTypes.FETCH_MOB_BANNERS_SUCCESS,
        payload: data.data
    }
}
export const fetchHomebannerSuccess = (data) => {
    return {
        type: actionTypes.FETCH_HOME_BANNER_SUCCESS,
        payload: data.data
    }
}
export function getDeskbanners(slug='home') {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-desk-banners/${slug}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchDeskbannerSuccess(data))
        });
    }
}
export function getMobbanner(slug='home') {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-mob-banners/${slug}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' , 'X-Auth-Token': `${localStorage.getItem('token')}`}),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchMobbannerSuccess(data))
        });
    }
}
export function gethomeSinglebanner(id) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-homebanner/${id}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchHomebannerSuccess(data))
        });
    }
}