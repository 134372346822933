import React, { Component } from 'react';
import XRHeader from './XRHeader';
import XRFooter from './XRFooter';
import XRSwitch from './../../components/xrgames/XRSwitch';

import $ from 'jquery';

class XRInnerLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            light_theme:true
        }
    }
    componentDidMount(){
        // if(localStorage.getItem('sc_theme_yf')){
        //     this.setState({
        //         light_theme:localStorage.getItem('sc_theme_yf') == 'true' ? true : false
        //     })
        // }else{
        //     this.setState({
        //         light_theme:true
        //     })
        //     localStorage.setItem("sc_theme_yf", true);
        // }
    }
    render() {
        $(window).on('load', function () {
            $('input[type=text], input[type=password]').attr('autoComplete', 'off');
        });
       
        return (
            < >
                
                <div className={`main-content-sec`}>
                {/* <div className={`main-content-sec ${this.state.light_theme  ? '' : 'sc-yf'}`}> */}
                    <XRHeader />
                    <div className="profile-banner">
                        {/* <div className="custom-container"> */}
                            <div className="">
                                {/* <div className="row">
                                    <div className="col-md-12 my-4">
                                        <XRSwitch />
                                    </div>
                                </div> */}
                                {/* <div className="page_content"> */}
                                    {/* <div className="main-content w-100"> */}
                                        {/* <div className=""> */}
                                            {/* <div className="sec games-list"> */}
                                                {this.props.children}
                                            {/* </div> */}
                                        {/* </div> */}
                                    {/* </div> */}
                                {/* </div> */}
                            </div>
                        {/* </div> */}
                    </div>
                    {/* <XRFooter /> */}
                </div>
            </>
        );
    }
}

export default XRInnerLayout;
