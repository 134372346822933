import * as types from "../types/types";

const initalState = {
    rates:[],
    ratesloading:false,
}

const matchReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.FETCH_RATES_START:
            return {
                ...state,
                ratesloading: true
            }
        case types.FETCH_RATES_SUCCESS:
            return {
                ...state,
                ratesloading: false,
                rates: action.payload
            } 
        default:
            return state
    }
}

export default matchReducer;