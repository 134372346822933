  
 import * as types from "../types/types";

 const initalState = {
    allplans:[],
    allplansloading: true,
    plan:[],
    planloading: true,
 }
 
 const plansReducer = (state = initalState, action) => {

     switch (action.type) {
        case types.FETCH_PLANS_BEGIN:
            return {
                ...state,
                allplansloading: true
            }
        case types.FETCH_PLANS_SUCCESS:
            return {
                ...state,
                allplansloading: false,
                allplans: action.payload
            }
        case types.FETCH_SINGLE_PLAN_BEGIN:
            return {
                ...state,
                planloading: true
            }
        case types.FETCH_SINGLE_PLAN_SUCCESS:
            return {
                ...state,
                planloading: false,
                plan: action.payload
            }
        default:
            return state
     }
 }
 
 export default plansReducer;