import * as types from "../types/types";

const initalState = {
    singleChallenge:[],
    singleChallengeloading:true,
    Challenges:[],
    Challengesloading:true,

    allinterestsapp:[],
    allinterestsapploading:true,

    influencerbyinterest:[],
    influencerbyinterestloading:true,

    homepagechallenges:[],
    homepagechallengesloading:true,

    

    Rewards:[],
    Rewardsloading:true,
    AllChallenges:[],
    AllChallengesloading:true,
    singleslugChallenge:'',
    singleslugChallengeloading:true,

    singlepostChallenge:'',
    singlepostChallengeloading:true,


    singleuserslugChallenge:'',
    singleuserslugChallengeloading:true,

    getjoinedplayersvotes: [],
    getjoinedplayersvotesloading: true,

    matchdata:[],
    matchdataloading:[],
    players:[],
    playersloading:true
}

const tournamentsReducer = (state = initalState, action) => {

    

    switch (action.type) {
        case types.FETCH_SINGLE_CHALLENGE_BEGIN:
            return {
                ...state,
                singleChallengeloading: true,
            }
        case types.FETCH_SINGLE_CHALLENGE_SUCCESS:
            return {
                ...state,
                singleChallenge: action.payload,
                singleChallengeloading: false
            }
        case types.FETCH_SINGLE_CHALLENGE_USER_SLUG_BEGIN:
            return {
                ...state,
                singleuserslugChallengeloading: true,
            }
        case types.FETCH_SINGLE_CHALLENGE_USER_SLUG_SUCCESS:
            return {
                ...state,
                singleuserslugChallenge: action.payload,
                singleuserslugChallengeloading: false
            }

            case types.FETCH_JOINED_PLAYERS_VOTES_BEGIN:
            return {
                ...state,
                getjoinedplayersvotesloading: true,
            }
            case types.FETCH_JOINED_PLAYERS_VOTES_SUCCESS:
            return {
                ...state,
                getjoinedplayersvotes: action.payload,
                getjoinedplayersvotesloading: false
            }
            
        case types.FETCH_SINGLE_CHALLENGE_SLUG_BEGIN:
            return {
                ...state,
                singleslugChallengeloading: true,
            }
        case types.FETCH_SINGLE_CHALLENGE_SLUG_SUCCESS:
            return {
                ...state,
                singleslugChallenge: action.payload,
                singleslugChallengeloading: false
            }

            case types.FETCH_SINGLE_CHALLENGE_POST_BEGIN:
                return {
                    ...state,
                    singlepostChallengeloading: true,
                }
            case types.FETCH_SINGLE_CHALLENGE_POST_SUCCESS:
                return {
                    ...state,
                    singlepostChallenge: action.payload,
                    singlepostChallengeloading: false
                }


        case types.FETCH_CHALLENGES_BEGIN:
            return {
                ...state,
                Challengesloading: true,
            }
        case types.FETCH_CHALLENGES_SUCCESS:
            return {
                ...state,
                Challenges: action.payload,
                Challengesloading: false
            }
            case types.FETCH_ALL_INTERESTS_APP_BEGIN:
                return {
                    ...state,
                    allinterestsapploading: true,
                }
            case types.FETCH_ALL_INTERESTS_APP_SUCCESS:
                return {
                    ...state,
                    allinterestsapp: action.payload,
                    allinterestsapploading: false
                }    

                case types.FETCH_INFLUENCER_BY_INTEREST_BEGIN:
                return {
                    ...state,
                    influencerbyinterestloading: true,
                }
                case types.FETCH_INFLUENCER_BY_INTEREST_SUCCESS:
                return {
                    ...state,
                    influencerbyinterest: action.payload,
                    influencerbyinterestloading: false
                }

                case types.FETCH_HOMEPAGE_CHALLENGES_BEGIN:
                return {
                    ...state,
                    homepagechallengesloading: true,
                }
                case types.FETCH_HOMEPAGE_CHALLENGES_SUCCESS:
                return {
                    ...state,
                    homepagechallenges: action.payload,
                    homepagechallengesloading: false
                }


        case types.FETCH_ALL_CHALLENGES_BEGIN:
            return {
                ...state,
                AllChallengesloading: true,
            }
        case types.FETCH_ALL_CHALLENGES_SUCCESS:
            return {
                ...state,
                AllChallenges: action.payload,
                AllChallengesloading: false
            }
        case types.FETCH_USER_REWARD_BEGIN:
            return {
                ...state,
                Rewardsloading: true,
            }
        case types.FETCH_USER_REWARD_SUCCESS:
            return {
                ...state,
                Rewards: action.payload,
                Rewardsloading: false
            }
        case types.FETCH_SINGLE_MATCH_BEGIN:
            return {
                ...state,
                matchdataloading: true,
            }
        case types.FETCH_SINGLE_MATCH_SUCCESS:
            return {
                ...state,
                matchdata: action.payload,
                matchdataloading: false
            }
        case types.FETCH_CHALLENGE_PLAYERS_BEGIN:
            return {
                ...state,
                playersloading: true,
            }
        case types.FETCH_CHALLENGE_PLAYERS_SUCCESS:
            return {
                ...state,
                players: action.payload,
                playersloading: false
            }
        default:
            return state
    }
}

export default tournamentsReducer;