import { combineReducers } from 'redux';
import matchReducer from './matchReducer';
import phoneRuleReducer from './phoneRuleReducer';
import sticketsReducer from './sticketsReducer';
import tournamentsReducer from './tournamentsReducer';
import gamesReducer from './gamesReducer';
import teamReducer from './teamReducer';
import ecommerceReducer from './ecommerceReducer';
import nftReducer from './nftReducer';
import coinpayReducer from './coinpayReducer';
import rewardsReducer from './rewardsReducer';
import ordersReducer from './ordersReducer';
import userReducer from './userReducer';
import transactionsReducer from './transactionsReducer';
import messageReducer from './messageReducer';
import eventsReducer from './eventsReducer';
import metaReducer from './metaReducer';
import bracketsReducer from './bracketsReducer';
import notificationsReducer from './notificationsReducer';
import bannerReducer from './bannerReducer';
import SponsorReducer from './SponsorReducer';
import MediaReducer from './MediaReducer';
import StreamReducer from './StreamReducer';
import XrDynamicReducer from './XrDynamicReducer';
import RelationReducer from './RelationReducer';
import challengeReducer from './challengeReducer';
import plansReducer from './plansReducer';
import stripeReducer from './stripeReducer';
import TrackReducer from './TrackReducer';
import MenuReducer from './MenuReducer';
import communitiesReducer from './communitiesReducer';
import FirebaseReducer from './FirebaseReducer' ;
import LobbyReducer from './LobbyReducer';
export default combineReducers({
    match: matchReducer,
    rules:phoneRuleReducer,
    stickets:sticketsReducer,
    tournaments:tournamentsReducer,
    games:gamesReducer,
    team:teamReducer,
    rewards:rewardsReducer,
    orders:ordersReducer,
    userDetails:userReducer,
    transactions:transactionsReducer,
    sendmess: messageReducer,
    events: eventsReducer,
    meta: metaReducer,
    brackets: bracketsReducer,
    notifications:notificationsReducer,
    banners:bannerReducer,
    lobbyimg:LobbyReducer,
    sponsors:SponsorReducer,
    news:MediaReducer,
    stream:StreamReducer,
    ecommerce:ecommerceReducer,
    nft:nftReducer,
    relation:RelationReducer,
    coinpay:coinpayReducer,
    dynamic:XrDynamicReducer,
    challenge:challengeReducer,
    plans:plansReducer,
    stripe:stripeReducer,
    track:TrackReducer,
    menu:MenuReducer ,
    communities:communitiesReducer,
    firebase:FirebaseReducer
});