// import { createStore, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';
// import rootReducer from './reducer/rootReducer';
// import { composeWithDevTools } from 'redux-devtools-extension';

// const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

// export default store;


// import { createStore, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';
// import rootReducer from './reducer/rootReducer';
// import { composeWithDevTools } from 'redux-devtools-extension';
// import { createTracker } from 'redux-segment';
// const tracker = createTracker();
// const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk,tracker)));

// export default store;


import { applyMiddleware, createStore, compose } from 'redux';
// import { reduxReactRouter } from 'redux-router'
// import createHistory from 'history/lib/createBrowserHistory'
// import routes from '../routes'
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk'
// import api from '../middleware/api'
import rootReducer from './reducer/rootReducer'
import { createTracker } from 'redux-segment';
 
const tracker = createTracker();                                   // Create the tracker...
 
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk,tracker)));
 
export default store;